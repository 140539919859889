<template>
    <modal
        title="Flag Fraud Account"
        :show="show"
        @close="$emit('close')"
    >
        <form-container
            id="updateFlagFraudForm"
            ref="updateFlagFraudForm"
            @on-submit="onSubmitFraudFlag"
        >
            <div
                v-if="errorUpdatingFlagFraudText"
                class="alert alert-warning"
                role="alert"
            >
                {{ errorUpdatingFlagFraudText }}
            </div>

            <div
                class="form-row"
                v-for="reason in fraudReasons"
                :key="reason.key"
            >
                <div class="mb-2 col">
                    <div class="form-check">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            :id="reason.key"
                            :value="reason.value"
                            v-model="fraudCheckedReasons"
                        >
                        <label
                            class="form-check-label"
                            :for="reason.key"
                        >{{ reason.label }}</label>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="mb-2 col">
                    <form-text-area
                        v-model="fraudNotes"
                        name="fraudNotes"
                        class="form-field"
                        placeholder="Add additional notes here"
                    />
                </div>
            </div>
            <div class="d-grid">
                <form-button
                    class="mb-2"
                    label="Flag account"
                    type="submit"
                    :disable-button="isSubmitDisabled"
                />
            </div>
        </form-container>
    </modal>
</template>

<script>
    import Modal from '@/components/Modal'
    import FormTextArea from '@/components/base/FormTextArea'
    import FormContainer from '@/components/base/FormContainer'
    import FormButton from '@/components/base/FormButton'
    import format from '@/mixins/format'
    import { kebabCase, startCase } from 'lodash'
    import { NOTES_REQUIRED_ERROR } from '@/data/constants'
    import { FraudFlagReasons } from '@/utils/systemFraudFlagsHelper'

    export default {
        name: 'FlagFraudAccountModal',
        props: {
            show: { type: Boolean, default: false },
        },
        mixins: [format],
        components: {
            modal: Modal,
            'form-text-area': FormTextArea,
            'form-container': FormContainer,
            'form-button': FormButton,
        },
        data() {
            return {
                errorUpdatingFlagFraudText: null,
                fraudReasons: Object.values(FraudFlagReasons).map((reason) => {
                    return {
                        key: kebabCase(reason),
                        label: startCase(reason),
                        value: reason,
                    }
                }),
                fraudNotes: '',
                fraudCheckedReasons: [],
            }
        },
        computed: {
            isSubmitDisabled() {
                return this.fraudCheckedReasons.length === 0
            },
            computedNotes() {
                let notes = ''
                if (this.fraudCheckedReasons.length !== 0) {
                    notes += this.fraudCheckedReasons.join('.\n')
                    notes += '.\n'
                }
                if (this.fraudNotes !== '') {
                    notes += this.fraudNotes
                    notes += '\n'
                }
                return notes
            },
        },
        methods: {
            onSubmitFraudFlag: async function () {
                const isValid = await this.$refs.updateFlagFraudForm.$refs.observer.validate()
                if (!isValid) {
                    this.errorUpdatingFlagFraudText = NOTES_REQUIRED_ERROR
                    return
                }
                this.$emit('close')
                this.$emit('submit', { reasonEnums: this.fraudCheckedReasons, reasons: this.computedNotes })
                this.$logEvent('click_button_submit_fraud_flag')
            },
        },
    }
</script>
