<template>
    <div>
        <validation-provider
            ref="provider"
            :mode="validationMode"
            :rules="validation"
            :vid="name"
            v-slot="{ errors }"
        >
            <div class="input">
                <label
                    v-if="label"
                    :for="name"
                >
                    {{ label }}
                </label>

                <div class="input-group">
                    <textarea
                        :rows="rows"
                        class="form-control"
                        ref="formFieldInput"
                        :id="name"
                        :name="name"
                        :placeholder="dynamicPlaceholder"
                        :class="{ 'is-invalid': errors[0], 'text-muted': disabled }"
                        :value="value"
                        @input="onInput($event.target)"
                    />
                    <div class="invalid-feedback">
                        {{ errors[0] }}
                    </div>
                </div>
            </div>
        </validation-provider>
    </div>
</template>

<script>
    import { formInputMixin } from '@/mixins/formInputMixin'

    export default {
        name: 'FormTextArea',
        mixins: [formInputMixin],
        props: {
            rows: {
                type: Number,
                default: null,
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '../../styles/components/base/formField';
</style>
