<template>
    <div class="last-transfer-document-iframe-container">
        <div id="lastTransferControls">
            <button
                class="btn btn-secondary m-2 small"
                :disabled="this.currentLastTransferDocIndex <= 0 || this.viewingState === ViewingState.loading"
                :class="this.currentLastTransferDocIndex <= 0 || this.viewingState === ViewingState.loading ? 'btn-outline-secondary' : 'btn-secondary'"
                @click="() => this.currentLastTransferDocIndex--"
            >
                Previous Document
            </button>
            <div class="d-flex flex-column py-2">
                <p
                    class="small me-auto my-0"
                    v-html="prettyDocumentName"
                />
                <p class="small text-muted ms-0 my-0">
                    Document {{ this.currentLastTransferDocIndex + 1 }} of {{ this.lastTransferDocNames.length }}
                </p>
            </div>
            <button
                class="btn btn-secondary m-2 small ms-auto"
                :disabled="this.currentLastTransferDocIndex === this.lastTransferDocNames.length - 1 || this.viewingState === ViewingState.loading"
                :class="this.currentLastTransferDocIndex === this.lastTransferDocNames.length - 1 || this.viewingState === ViewingState.loading ? 'btn-outline-secondary' : 'btn-secondary'"
                @click="() => this.currentLastTransferDocIndex++"
            >
                Next Document
            </button>
        </div>
        <div
            class="last-transfer-document-error-container alert alert-warning"
            v-show="this.viewingState === ViewingState.error"
            role="alert"
        >
            <span v-html="errorText" />
            <div class="d-grid">
                <button
                    class="btn btn-primary mt-3"
                    @click="waitForCredentials"
                >
                    Reset
                </button>
            </div>
        </div>
        <div class="last-transfer-document-iframe-container">
            <loading-indicator
                v-show="this.viewingState === ViewingState.loading"
                :title="loadingText"
            />
            <!-- Note: this NEEDS to be an embed instead of an iframe.
Iframe creates an embed internally but only works intermittently on Google Chrome -->
            <embed
                type="application/pdf"
                :src="lastTransferDocumentBlobUrl"
                class="last-transfer-document-iframe"
                v-show="this.viewingState === ViewingState.showingIframe"
            >
        </div>
    </div>
</template>

<script>
    import LoadingIndicator from '@/components/LoadingIndicator'
    import { getAvailableLastTransferDocNames, getLegalDocumentForNotary, startFetchTransferDocumentForNotary } from '@/services/api'
    import DateTime from 'luxon/src/datetime'
    import { logger } from '@/utils/logger'
    import { toProperCase } from '@/utils/remoteNotarization'
    import { systemFraudFlagsHelper, AutoFraudFlagReasons } from '@/utils/systemFraudFlagsHelper'

    const ViewingState = {
        error: 'error',
        loading: 'loading',
        showingIframe: 'showingIframe',
    }

    export default {
        name: 'LastTransferDocument',
        props: {
            applicantId: { type: Number },
            loanApplicationId: { type: Number },
            applicantNotaryAssignmentId: { type: Number },
        },
        components: {
            'loading-indicator': LoadingIndicator,
        },
        data: function () {
            return {
                numberOfPolls: 0,
                maxNumberOfPolls: 30,
                pollIntervalInMs: 1000,
                errorText: null,
                lastTransferDocNames: [],
                currentLastTransferDocIndex: null,
                lastTransferDocumentBlobUrl: null,
                ViewingState,
                loadingText: 'Loading...',
            }
        },
        mounted: async function () {
            await this.waitForCredentials()
        },
        watch: {
            currentLastTransferDocIndex: async function (currentDocIndex) {
                logger.log(`Current property description doc idx changed to: ${currentDocIndex}, pulling new doc`)

                if (this.isMissingCredentials) {
                    logger.log('Refusing to fetch new documents while missing credentials')
                    return
                }

                if (currentDocIndex >= 0 && this.lastTransferDocNames?.[currentDocIndex]) {
                    await this.downloadLastTransferDocument(this.lastTransferDocNames[currentDocIndex])
                } else {
                    logger.log('Missing any property description docs, refusing to download :-(')
                }
            },
        },
        computed: {
            isMissingCredentials: function () {
                return !this.loanApplicationId || !this.applicantId
            },
            viewingState: function () {
                if (this.errorText) return ViewingState.error
                if (!this.lastTransferDocumentBlobUrl) return ViewingState.loading
                return ViewingState.showingIframe
            },
            prettyDocumentName: function () {
                const rawDocName = this.lastTransferDocNames[this.currentLastTransferDocIndex]
                if (!rawDocName) {
                    return 'N/A'
                }

                const splitDocName = rawDocName.split('|')
                if (splitDocName[0] === 'coreLogic') {
                    return `<span class="fw-bold">Source:</span> ${toProperCase(splitDocName[0])} • <span class="fw-bold">Date:</span> ${DateTime.fromFormat(splitDocName[2], 'yyyyMMdd').toFormat(
                        'dd/MM/yyyy'
                    )} • <span class="fw-bold">Doc:</span> ${splitDocName[1]}`
                } else {
                    return `Source: ${toProperCase(splitDocName[0])} • Date: N/A • Doc: N/A`
                }
            },
        },
        methods: {
            waitForCredentials: async function () {
                this.errorText = null
                this.lastTransferDocumentBlobUrl = null

                try {
                    if (this.isMissingCredentials) {
                        this.loadingText = 'Waiting for credentials to become available'
                        logger.log('Still missing credentials, polling...')
                        setTimeout(this.waitForCredentials, this.pollIntervalInMs)
                        return
                    }
                    logger.log('Got credentials!')

                    this.$logEvent('event_getting_last_transfer_document_names')
                    this.loadingText = 'Getting list of available transfer documents'
                    const transferDocNamesReq = await getAvailableLastTransferDocNames(this.applicantNotaryAssignmentId)
                    this.lastTransferDocNames = transferDocNamesReq.data.payload.transferDocs
                    logger.log(`Got property description doc names! ${this.lastTransferDocNames}`)

                    if (this.lastTransferDocNames.length > 0) {
                        this.currentLastTransferDocIndex = 0
                    } else {
                        // This is so the UI shows (0 of 0) instead of (1 of 0)
                        this.currentLastTransferDocIndex = -1
                        this.loadingText = "We couldn't find any property description documents for this property 😔"
                    }
                } catch (error) {
                    logger.error(`Error retrieving lastTransferDocNames`, null /* event */, error)
                    this.errorText = 'Error fetching list of transfer docs. Reset?'
                }
            },
            downloadLastTransferDocument: async function (lastTransferDocName) {
                // Set null to enable loading screen
                this.errorText = null
                this.lastTransferDocumentBlobUrl = null
                logger.log(`Trying to download lastTransferDoc: ${lastTransferDocName}`)

                try {
                    this.$logEvent('event_downloading_last_transfer_document', { lastTransferDocName })
                    this.loadingText = 'Requesting property description document...'
                    // This kicks off the process, but we can expect to loop for a while until it is ready
                    await startFetchTransferDocumentForNotary(lastTransferDocName, this.applicantNotaryAssignmentId)
                } catch (error) {
                    logger.error(`Error starting fetch for property description document`, null /* event */, error)
                    this.errorText = 'Error starting fetch for document. Try ← or → documents if available'
                }

                // Reset our polling counter
                this.numberOfPolls = 0
                await this.waitForTransferDocumentToBecomeAvailable()
            },
            waitForTransferDocumentToBecomeAvailable: async function () {
                this.loadingText = 'Waiting for property description document to become available...'
                let shouldFlag = false
                try {
                    const response = await getLegalDocumentForNotary('LastTransferDocument', this.loanApplicationId, this.applicantId)

                    const blob = new Blob([response.data], { type: 'application/pdf' })
                    logger.info('Successfully retrieved property description document blob, creating blob url')
                    this.lastTransferDocumentBlobUrl = URL.createObjectURL(blob) + '#toolbar=0'
                    this.$logEvent('event_successfully_downloaded_last_transfer_document', { lastTransferDocumentBlobUrl: this.lastTransferDocumentBlobUrl })

                    this.errorText = null
                } catch (e) {
                    // HTTP 423 === "Locked"
                    logger.log('waiting for property description...')
                    if (e?.response?.status === 423) {
                        logger.log('Property description doc not available yet')
                        if (this.numberOfPolls < this.maxNumberOfPolls) {
                            this.numberOfPolls += 1
                            setTimeout(this.waitForTransferDocumentToBecomeAvailable, this.pollIntervalInMs)
                        } else {
                            console.log('Giving up on polling, unlocking buttons and showing error message')
                            this.errorText = 'Document loading timed out, try ← or → documents if available'
                            shouldFlag = true
                        }
                    } else {
                        // Looks like something else went wrong
                        logger.error(`Error fetch document for property description document`, null /* event */, e)
                        this.errorText = 'Error fetching property description document, Try ← or → if other documents are available'
                        shouldFlag = true
                    }
                }

                if (shouldFlag) {
                    const rawDocName = this.lastTransferDocNames[this.currentLastTransferDocIndex] || 'N/A'
                    systemFraudFlagsHelper.addFlag({
                        reasonEnums: [AutoFraudFlagReasons.errorFetchingPropertyDescription],
                        reasons: `Error fetching property description document during notarization; ${rawDocName}`,
                    })
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    #lastTransferControls {
        display: flex;
        justify-content: space-between;
        align-content: center;

        p {
            margin: auto;
        }
    }

    .last-transfer-document-iframe-container {
        height: 100%;
        width: 100%;

        .last-transfer-document-error-container {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
        }

        .last-transfer-document-iframe {
            width: 100%;
            height: 100%;
            border: none;
        }
    }
</style>
