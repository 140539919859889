<template>
    <modal
        title="Why did this notary session fail?"
        :show="show"
        @close="onClose"
    >
        <form-container
            id="failWithMessageForm"
            ref="failWithMessageForm"
            @on-submit="onSubmitSessionFailure"
        >
            <div
                v-if="errorFailingSessionWithReason"
                class="alert alert-warning"
                role="alert"
            >
                {{ errorFailingSessionWithReason }}
            </div>

            <div class="form-row">
                <div class="mb-1 col">
                    <form-radio-group
                        :options="options"
                        name="failureReason"
                        v-model="failureReason"
                        validation-rules="required"
                        input-row-class="custom-control custom-radio mb-0"
                    />
                </div>
            </div>
            <div class="form-row">
                <div class="mb-4 col">
                    <form-text-area
                        v-model="failureReasonDescription"
                        name="failureReasonDescription"
                        placeholder="Any interesting details to add?"
                    />
                </div>
            </div>
            <div
                class="form-row"
                v-if="modalState.sessionType === SessionType.RON"
            >
                <div class="mb-1 col">
                    <form-checkbox-group
                        :initial-value="checkboxValues"
                        v-model="checkboxValues"
                        data-testid="send-to-l2-notary"
                        class="mb-5"
                        group-name="checklist"
                    />
                </div>
            </div>
            <div class="d-grid">
                <form-button
                    class="mb-2"
                    :label="submitButtonLabel"
                    type="submit"
                />
            </div>
        </form-container>
    </modal>
</template>

<script>
    import Modal from '@/components/Modal'
    import FormTextArea from '@/components/base/FormTextArea'
    import FormContainer from '@/components/base/FormContainer'
    import FormButton from '@/components/base/FormButton'
    import { NotaryFailureReason, notaryFailureReasonText, SessionType } from '@/services/remoteNotarizationApi'
    import FormRadioGroup from '@/components/base/FormRadioGroup'
    import format from '@/mixins/format'
    import { SessionState } from '@/utils/remoteNotarization'
    import { logger } from '@/utils/logger'
    import FormCheckboxGroup from '@/components/base/FormCheckboxGroup'

    const NOTES_REQUIRED_ERROR = 'Please provide the reason this session is ending in failure'

    export default {
        name: 'FailureReasonModal',
        props: {
            show: { type: Boolean, default: false },
            modalState: {
                required: true,
                type: Object,
            },
        },
        mixins: [format],
        components: {
            FormCheckboxGroup,
            modal: Modal,
            'form-text-area': FormTextArea,
            'form-container': FormContainer,
            'form-button': FormButton,
            'form-radio-group': FormRadioGroup,
        },
        data() {
            return {
                SessionState,
                SessionType,
                errorFailingSessionWithReason: null,
                failureReason: null,
                checkboxValues: {
                    'Send to L2 Notary?': { value: false },
                },
                failureReasonDescription: '',
            }
        },
        computed: {
            submitButtonLabel() {
                if (this.modalState.sessionState === SessionState.sessionCompleteFailure) {
                    return 'Confirm end with failure'
                } else {
                    return 'Confirm end with failure (re-notarize all)'
                }
            },
            options() {
                return Object.values(NotaryFailureReason).map((referralFrom) => {
                    return {
                        name: notaryFailureReasonText[referralFrom],
                        value: referralFrom,
                    }
                })
            },
        },
        methods: {
            onClose: function () {
                this.errorFailingSessionWithReason = null
                this.failureReasonDescription = ''
                logger.info('Closing failure reason modal')
                this.$emit('close')
            },
            onSubmitSessionFailure: async function () {
                const isValid = await this.$refs.failWithMessageForm.$refs.observer.validate()
                if (!isValid) {
                    this.errorFailingSessionWithReason = NOTES_REQUIRED_ERROR
                    return
                }
                const args = {
                    sessionState: this.modalState.sessionState,
                    failureReason: this.failureReason,
                    failureReasonDescription: this.failureReasonDescription,
                    sendToL2Notary: this.checkboxValues['Send to L2 Notary?']?.value,
                }
                this.errorFailingSessionWithReason = null
                this.failureReasonDescription = ''
                this.failureReason = null
                logger.info(`onSubmitSessionFailure with ${JSON.stringify(args)}`)
                this.$emit('submit', args)
            },
        },
    }
</script>
