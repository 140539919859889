<template>
    <div class="h-100 flex-column d-flex">
        <nav
            id="navbar"
            class="sticky-top flex-nowrap justify-content-between align-items-center"
            :class="hideNav ? 'pt-2 mx-2' : 'navbar px-2'"
        >
            <slot
                v-show="isLoggedIn && !hideNav"
                name="leftNav"
            />
            <a
                class="nav-brand d-flex justify-content-between text-decoration-none"
                v-if="!isLoggedIn"
            >
                <div class="w-25">
                    <img
                        src="../../assets/images/global/aven.svg"
                        :height="hideNav ? '27' : '18'"
                        alt="Aven Logo"
                    >
                </div>
                <div
                    v-if="hideNav"
                    class="text-center pt-2 w-25"
                >
                    <div class="section-header text-primary">Admin</div>
                </div>
                <div class="w-25">&nbsp;</div>
            </a>
            <div
                v-if="isLoggedIn && !hideNav"
                class="right"
            >
                <span
                    id="timer"
                    v-if="timerText"
                    v-show="false"
                >{{ timerText }}</span>
                <span class="status-indicator-online" />Online
            </div>
            <div
                v-else-if="!hideNav"
                class="right"
            >
                <span class="status-indicator-offline" />Offline
            </div>
            <slot
                v-show="isLoggedIn && !hideNav"
                name="rightNav"
            />
        </nav>

        <div class="container-fluid flex-grow-1 d-flex flex-column">
            <div
                v-show="errorText.length > 0"
                class="alert alert-warning"
                role="alert"
            >
                <span v-html="errorText" />
            </div>
            <div
                v-if="loading"
                class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom h-100"
            >
                <loading-indicator
                    :title="loadingTitle"
                    :content="loadingSubtitle"
                />
            </div>
            <slot v-show="!loading" />
        </div>
    </div>
</template>

<script>
    import LoadingIndicator from '@/components/LoadingIndicator'

    export default {
        name: 'NotaryLayout',
        props: {
            hideNav: {
                type: Boolean,
                default: false,
            },
            pageName: {
                type: String,
                required: true,
            },
            loading: {
                type: Boolean,
                required: false,
            },
            loadingTitle: {
                type: String,
                default: 'Loading...',
            },
            loadingSubtitle: {
                type: String,
                default: '',
            },
            errorText: {
                type: String,
                required: false,
            },
            isLoggedIn: {
                type: Boolean,
                default: true,
            },
            timeRemainingDuration: {
                type: Object,
                required: false,
            },
        },
        computed: {
            timerText: function () {
                if (!this.timeRemainingDuration) return null

                if (this.timeRemainingDuration.milliseconds <= 0) {
                    return '0:00 Mins'
                }

                return this.timeRemainingDuration.toFormat('mm:ss') + ' Mins'
            },
        },
        components: {
            'loading-indicator': LoadingIndicator,
        },
    }
</script>

<style lang="scss">
    @import '../../styles/layouts/remoteNotarization/notary';
</style>
