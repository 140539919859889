<template>
    <div>
        <div>
            <span> Pre-session Result: </span>
            <a
                v-show="!status && !isLoading"
                class="fw-bold"
                @click="fetchVerificationResult"
                href="#"
            > fetch </a>
            <span
                v-show="isLoading"
                class="spinner-border spinner-border-sm text-secondary mx-0"
            />
            <a
                v-show="status && !isLoading"
                class="fw-bold"
                @click="reset"
                href="#"
            > reset </a>
        </div>
        <div
            class="alert alert-warning"
            v-show="errorText"
            role="alert"
        >
            <span>{{ errorText }}</span>
        </div>
        <div v-show="!isLoading && !errorText && status">
            <p class="mb-0">
                <span class="text-muted">Status: </span>
                <span class="fw-bold">
                    {{ status }}
                </span>
            </p>
            <p class="mb-0">
                <span class="text-muted">Document Type: </span>
                <span class="fw-bold">
                    {{ documentType }}
                </span>
            </p>
            <p class="mb-0">
                <a
                    class="fw-bold"
                    :href="inquiryPageLink"
                    target="_blank"
                > Persona Link </a>
            </p>
        </div>
    </div>
</template>
<script>
    import { logger } from '@/utils/logger'
    import { getPreSessionIdVerificationInfo, PersonaVerificationStatus } from '@/services/remoteNotarizationApi'
    import { inspect } from '@/utils/inspect'
    import { AutoFraudFlagReasons, systemFraudFlagsHelper } from '@/utils/systemFraudFlagsHelper'

    export default {
        name: 'PersonaPreSessionIdVerification',
        props: {
            applicantId: { type: Number },
            loanApplicationId: { type: Number },
        },
        data() {
            return {
                isLoading: false,
                errorText: null,
                // extracted components of verification report
                inquiryId: null,
                inquiryPageLink: null,
                status: null,
                needsReviewChecks: [],
                documentType: null,
            }
        },
        methods: {
            fetchVerificationResult: async function () {
                this.isLoading = true
                try {
                    const response = await getPreSessionIdVerificationInfo(this.applicantId, this.loanApplicationId)
                    logger.info(`getPreSessionIdVerificationInfo: ${inspect(response)}`)
                    if (!response.data.success) {
                        logger.log(`failed to fetch pre-session persona inquiry info for A: ${this.applicantId} | L: ${this.loanApplicationId}: ${response.data.error}`)
                        this.errorText = response.data.error
                        return
                    }
                    const verificationPayload = response.data.payload
                    this.inquiryId = verificationPayload.inquiryId
                    this.inquiryPageLink = verificationPayload.inquiryPageLink
                    this.status = verificationPayload.status
                    this.needsReviewChecks = verificationPayload.needsReviewChecks
                    this.documentType = verificationPayload.documentType || 'N/A'

                    if (this.status === PersonaVerificationStatus.needsReview) {
                        systemFraudFlagsHelper.addFlag({
                            reasonEnums: [AutoFraudFlagReasons.preSessionPersonaVerificationNeedsReview],
                            reasons: `Pre-session Persona ID verification (inquiryId: ${this.inquiryId}): Needs Review for failing checks ${this.needsReviewChecks.join(',')}`,
                        })
                    }
                } catch (error) {
                    logger.log(`Error pulling pre-session ID verification info for A: ${this.applicantId} | L: ${this.loanApplicationId}: ${error}`)
                    this.errorText = 'something is wrong, please retry'
                } finally {
                    this.isLoading = false
                }
            },
            reset: function () {
                logger.info(`Resetting pre-session ID verification for A: ${this.applicantId} | L: ${this.loanApplicationId}`)
                this.isLoading = false
                this.errorText = null
                this.inquiryId = null
                this.inquiryResultLink = null
                this.status = null
                this.needsReviewChecks = []
                this.documentType = null
            },
        },
    }
</script>

<style lang="scss" scoped>
    a {
        color: inherit;
        text-decoration: underline;

        &:hover {
            color: $blue;
            transition: all 0.3s;
        }
    }
</style>
