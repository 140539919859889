<template>
    <div>
        <!--
      Having just the validation provider wrap the prompt and hidden input
      If provider wraps the for loop, it causes an infinite loop
      This also allows to show the error on the prompt
    -->
        <validation-provider
            :mode="validationMode"
            :rules="validation"
            v-slot="{ errors }"
        >
            <input hidden>
            <p
                v-if="prompt || errors.length > 0"
                class="text-start fw-bold"
                :class="{ 'text-danger': errors[0] }"
            >
                {{ prompt }}
                <span
                    class="fw-normal small block"
                    v-if="errors[0]"
                ><br>{{ errors[0] }}</span>
            </p>
        </validation-provider>

        <div
            class="text-start"
            v-for="(optionNameValuePair, index) in optionPairs"
            :key="groupName + optionNameValuePair + index"
        >
            <div class="custom-control custom-checkbox">
                <input
                    type="checkbox"
                    class="custom-control-input"
                    :name="groupName + optionNameValuePair + index"
                    @change="(event) => onChange(event, optionNameValuePair[0])"
                    :id="groupName + optionNameValuePair + index"
                    :checked="optionNameValuePair[1].value"
                    :disabled="!optionNameValuePair[1].userEditable"
                >
                <label
                    class="custom-control-label"
                    :for="groupName + optionNameValuePair + index"
                >{{ optionNameValuePair[0] }}</label>
            </div>
        </div>
    </div>
</template>

<script>
    import { ValidationProvider } from 'vee-validate'
    import { mapValues, pickBy, toPairs } from 'lodash'
    import { logger } from '@/utils/logger'
    import { inspect } from '@/utils/inspect'

    export default {
        name: 'FormCheckboxGroup',
        props: {
            prompt: String,
            groupName: String,
            // this is a hack until I figure out how to get pure two-way binding working
            // for now, initialValue is used to bootstrap the checkboxes
            // and v-model ("value") is used to read back the resulting changes
            initialValue: Object,
            validationMode: {
                type: String,
                default: 'lazy',
                validator: function (value) {
                    return ['eager', 'aggressive', 'passive', 'lazy'].indexOf(value) !== -1
                },
            },
            validation: {
                type: String,
                default: null,
            },
        },
        data() {
            return {
                options: this.processOptions(this.initialValue),
            }
        },
        mounted() {
            // This is necessary for callers to receive the "formatted" version of the options
            this.$emit('input', this.options)
        },
        components: {
            'validation-provider': ValidationProvider,
        },
        computed: {
            optionPairs() {
                return toPairs(this.options)
            },
        },
        watch: {
            initialValue(newValue, oldValue) {
                const oldStr = inspect(oldValue)
                const newStr = inspect(newValue)

                // Don't log if the "object" has changed but all of the values are the same
                if (newStr === oldStr) {
                    return
                }

                logger.log(`New initial value: ${newStr}`)
                this.options = this.processOptions(newValue)
            },
            options(newValue, oldValue) {
                const oldStr = inspect(oldValue)
                const newStr = inspect(newValue)

                // Don't log if the "object" has changed but all of the values are the same
                if (newStr === oldStr) {
                    return
                }

                logger.log(`New checklist options: ${newStr}`)
            },
        },
        methods: {
            processOptions(rawOptions) {
                const valuesWithDefaults = mapValues(rawOptions, (x) => ({
                    // Apply default values
                    value: x.value ?? false, // Default to unchecked
                    enabled: x.enabled ?? true, // Default to enabled
                    userEditable: x.userEditable ?? true, // default to enabled
                }))

                // Only pick enabled keys
                return pickBy(valuesWithDefaults, (x) => x.enabled)
            },
            onChange(event, optionName) {
                const newValue = event.target.checked
                this.options[optionName].value = newValue
                logger.log(`Checkbox change detected for ${optionName} to ${newValue}`)
                this.$emit('input', this.options)
            },
        },
    }
</script>

<style lang="scss" scoped>
    .custom-checkbox {
        margin-bottom: $spacer * 1.5;
    }
    :checked {
        display: block;
        margin-top: $spacer;
    }
</style>
