<template>
    <uploader
        :options="options"
        ref="uploader"
    >
        <uploader-unsupport />
        <uploader-drop class="p-0 border-0">
            <uploader-btn
                :attrs="attrs"
                class="d-flex border-0 w-100 h-100 mb-0"
            >
                <p class="section-header mb-0">
                    Scanned PDF Here
                </p>
            </uploader-btn>
        </uploader-drop>
        <uploader-list />
        <div class="d-flex justify-content-between mt-2">
            <button
                class="btn btn-primary btn-upload"
                @click="onClickClearPriorScannedUpload()"
                :disabled="isBusy"
            >
                Clear
            </button>
        </div>
    </uploader>
</template>
<script>
    import { appSessionStorage, localStorageKey } from '@/utils/storage'
    import uploader from 'vue-simple-uploader'
    import Vue from 'vue'
    import { clearPriorScannedDocumentUpload } from '@/services/remoteNotarizationApi'
    import { logger } from '@/utils/logger'
    Vue.use(uploader)
    const ON_UPLOAD_SUCCESS = 'on-upload-success'

    export default {
        name: 'ScannedFileUpload',
        data: function () {
            return {
                isBusy: false,
                isUploadInProgress: false,
                hasUploadCompleted: false,
                options: {
                    target: process.env.VUE_APP_API_BASE_URL + '/remoteNotarization/notary/uploadScannedDocumentPart',
                    testChunks: false,
                    headers: { notaryauthorization: `Bearer ${appSessionStorage.getItem(localStorageKey.notaryAccessJWT)}` },
                    singleFile: true,
                    simultaneousUploads: 1, // If you change this, the backend will explode 💣
                    chunkSize: 5 * 1024 * 1024, // ~5mb, same as aws minimum chunk size
                },
                attrs: {
                    accept: 'application/pdf',
                },
            }
        },
        mounted: function () {
            const uploader = this.$refs.uploader.uploader
            // Re-evaluate this on all events of the uploader
            uploader.on('complete', () => {
                this.hasUploadCompleted = true
                this.$emit(ON_UPLOAD_SUCCESS, {})
            })

            // This rejects any new files unless the user has cleared existing uploads
            uploader.on('fileAdded', () => {
                if (this.hasUploadCompleted) {
                    logger.info('You have already uploaded a file, press clear to upload a different file')
                    alert('You have already uploaded a file, press clear to upload a different file')
                    return false
                }
                if (uploader.files.length > 0) {
                    logger.info('You have a file upload in progress, press clear to upload a different file')
                    alert('You have a file upload in progress, press clear to upload a different file')
                    return false
                }

                logger.info('New file upload added')
                return true
            })
        },
        methods: {
            resetUiState: function () {
                logger.info('Resetting notary scanned file upload UI state')
                this.isBusy = false
                this.hasUploadCompleted = false
                const uploader = this.$refs.uploader.uploader
                // Clear any ongoing uploads (if any)
                uploader.cancel()
            },
            onClickClearPriorScannedUpload: async function () {
                try {
                    this.$logEvent('click_button_clear_prior_scanned_upload')
                    this.isBusy = true
                    this.hasUploadCompleted = false
                    const uploader = this.$refs.uploader.uploader
                    // Clear any ongoing uploads (if any)
                    uploader.cancel()
                    await clearPriorScannedDocumentUpload()
                    alert('Removed scanned file upload. Please upload again')
                } finally {
                    this.isBusy = false
                }
            },
        },
    }
</script>

<style lang="scss">
    .btn-upload {
        width: 50%;

        &:first-child {
            margin-right: 8px;
        }

        &:last-child {
            margin-left: 8px;
        }
    }

    .uploader {
        min-height: 127px;
        height: 100%;
        text-align: center;
        font-size: 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        overflow: hidden;
        border: 0;
    }

    .uploader-drop {
        border: 0;
        height: 127px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .uploader-btn {
        align-items: center;
        justify-content: center;
        border: none;
    }
</style>
