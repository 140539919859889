<template>
    <div class="notarial-certificate-container">
        <div
            class="notarial-certificate-error-container alert alert-warning"
            v-if="viewingState === ViewingState.error"
            role="alert"
        >
            <span v-html="errorText" />
            <div class="d-grid">
                <button
                    class="btn btn-primary mt-3"
                    @click="init"
                >
                    Reset
                </button>
            </div>
        </div>
        <div
            class="notarial-certificate-pdf-container"
            v-else
        >
            <loading-indicator
                v-show="viewingState === ViewingState.loading"
                :title="loadingText"
            />
            <!-- Note: this NEEDS to be an embed instead of an iframe.
            Iframe creates an embed internally but only works intermittently on Google Chrome
            We use embed for notary b/c it includes the various printing functionalities -->
            <embed
                v-if="viewingState === ViewingState.showingIframe"
                type="application/pdf"
                :src="notarialCertificateBlobUrl"
                class="notarial-certificate-iframe"
            >
        </div>
    </div>
</template>

<script>
    import LoadingIndicator from '@/components/LoadingIndicator'
    import { getLegalDocumentForNotary } from '@/services/api'
    import { logger } from '@/utils/logger'

    const ViewingState = {
        error: 'error',
        loading: 'loading',
        showingIframe: 'showingIframe',
    }

    export default {
        name: 'NotarialCertificate',
        props: {
            applicantId: { type: Number, default: null },
            loanApplicationId: { type: Number, default: null },
        },
        components: {
            'loading-indicator': LoadingIndicator,
        },
        data: function () {
            return {
                // This abomination is necessary in order to trigger the first 'watch' state transition
                // Without this, watch will not fire on the initial value
                hasLoaded: false,
                errorText: '',

                notarialCertificateBlobUrl: null,

                ViewingState,
                numberOfPages: null,
                loadingText: 'Loading...',
            }
        },
        computed: {
            // This is a watched value, your IDE may show it as 'unused'
            shouldFetchDocument: function () {
                // Credentials are available AND we haven't already fetched a doc
                return this.loanApplicationId && this.applicantId && !this.notarialCertificateBlobUrl && this.hasLoaded
            },
            viewingState: function () {
                if (this.errorText) return ViewingState.error
                if (!this.notarialCertificateBlobUrl) return ViewingState.loading
                return ViewingState.showingIframe
            },
        },
        mounted: function () {
            this.init()
        },
        watch: {
            shouldFetchDocument: async function (newState, oldState) {
                logger.info(`Updated shouldFetchDocument from ${oldState} to ${newState}`)

                if (!newState) {
                    return
                }

                await this.fetchNotarialCertificatePdf()
            },
        },
        methods: {
            init: async function () {
                // Reset our 'hasLoaded' state to trigger a refresh (if necessary)
                this.hasLoaded = false
                // Clear error text to trigger the loading screen
                this.errorText = null

                this.hasLoaded = true
            },
            fetchNotarialCertificatePdf: async function () {
                this.errorText = null
                this.loadingText = 'Fetching notarial certificate...'

                try {
                    logger.log('Attempting to fetch notarial certificate...')

                    const response = await getLegalDocumentForNotary('NotarialCertificate', this.loanApplicationId, this.applicantId)

                    const blob = response.data // as Blob
                    logger.info('Successfully retrieved notarial certificate document blob, creating blob url')
                    this.notarialCertificateBlobUrl = URL.createObjectURL(blob)

                    this.$logEvent('event_successfully_downloaded_notarial_certificate', { notarialCertificateBlobUrl: this.notarialCertificateBlobUrl })
                } catch (e) {
                    logger.error(`Error fetching document for notarial certificate`, null /* event */, e)
                    this.errorText = `We couldn't get the notarial certificate`
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    .notarial-certificate-container {
        height: 100%;
        overflow: auto;

        .notarial-certificate-error-container {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            margin: 0;
        }

        .notarial-certificate-pdf-container {
            height: 100%;
        }

        .notarial-certificate-iframe {
            height: 100%;
            width: 100%;
        }
    }
</style>
