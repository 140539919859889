<template>
    <!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
    <notary-layout
        :page-name="notaryName"
        :error-text="errorText"
        :time-remaining-duration="timeRemainingDuration"
    >
        <template #leftNav>
            <div class="flex-grow-1">
                <div v-if="userInfo">
                    <p class="mb-0">
                        First Name: <span class="fw-bold">{{ userFirstName }}</span> / Last Name: <span class="fw-bold">{{ userLastName }}</span> / Date of Birth:
                        <span class="fw-bold">{{ userDateOfBirthFormatted }}</span> / Marital Status: <span class="fw-bold">{{ userMaritalStatus }}</span>
                    </p>
                    <p class="mb-0 text-muted">
                        KBA: <span
                            class="fw-bold"
                            v-html="kbaStatus"
                        /> / Session Type: <span class="fw-bold">{{ sessionType }}</span>
                        <span v-if="sessionType !== SessionType.RON">
                            / Plotter Status: <span class="fw-bold">{{ plotterStatus }}</span>
                        </span>
                    </p>
                    <p class="mb-0 text-muted">
                        Home Owners: <span class="fw-bold">{{ homeOwnerNames }}</span> / Property Address: <span class="fw-bold">{{ propertyAddressFormatted }}</span> / Residence Type:
                        <span class="fw-bold">{{ homeResidenceType }}</span> / APN: <span class="fw-bold">{{ apn }}</span> / Personal Address:
                        <span class="fw-bold">{{ personalAddressFormatted }}</span>
                    </p>
                    <p
                        class="mb-0 text-muted"
                        v-if="livingTrustName"
                    >
                        <span
                            v-if="livingTrustName"
                        >Trust Name on Certificate: <span class="fw-bold">{{ livingTrustName }}</span></span>
                    </p>
                    <p
                        class="mb-0 text-muted"
                        v-if="livingTrustVestingClause"
                    >
                        <span
                            v-if="livingTrustVestingClause"
                        >Ownership Vesting on Certificate: <span class="fw-bold">{{ livingTrustVestingClause }}</span></span>
                    </p>
                    <p
                        class="mb-0 text-muted"
                        v-if="applicantChangedNameFrom"
                    >
                        <span v-if="applicantChangedNameFrom">
                            Applicant changed name from <span class="fw-bold">{{ applicantChangedNameFrom }}</span> to <span class="fw-bold">{{ userFirstName }} {{ userLastName }}</span> after owner
                            name mismatch detected
                        </span>
                    </p>
                    <p
                        class="mb-0 text-muted"
                        v-if="attestedTitleName"
                    >
                        <span v-if="attestedTitleName">
                            Applicant claimed to be <span class="fw-bold">{{ attestedTitleName }}</span>, a name listed on their home's title
                        </span>
                    </p>
                    <p class="mb-0 text-muted">
                        Other Signers:
                        <span v-if="otherApplicants.length === 0"> N/A</span>
                        <span v-else>
                            <span
                                v-for="(otherApplicant, index) in otherApplicants"
                                :key="index"
                            >
                                {{ otherApplicant.name }} ({{ otherApplicant.type }}) {{ otherApplicant.phone }} <span v-if="index !== otherApplicants.length - 1">/</span></span>
                        </span>
                    </p>
                </div>
                <div v-else>
                    <p class="fw-bold mb-0">
                        On Standby...
                    </p>
                </div>
            </div>
        </template>
        <template #rightNav>
            <div class="me-2">
                <div
                    class="dropdown float-right"
                    :class="{ show: uiViewingState.showActions }"
                    ref="dropdownButton"
                    @click="(e) => e.stopPropagation()"
                >
                    <button
                        class="btn btn-primary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-testid="dropdown-menu-button"
                        data-bs-toggle="dropdown"
                        data-bs-boundary="viewport"
                        @click="uiViewingState.showActions = !uiViewingState.showActions"
                    >
                        Actions
                    </button>
                    <div
                        class="dropdown-menu dropdown-menu-end"
                        :class="{ show: uiViewingState.showActions }"
                    >
                        <!-- Although userInfo /should/ be enough for the actions below,
                        some backend funcs expect you to be 'inSession' i.e. 'busy' before making changes-->
                        <div v-if="notaryBusyStatus === NotaryBusyStatus.busy">
                            <h6 class="dropdown-header">
                                Session Success
                            </h6>
                            <a
                                class="dropdown-item bg-success"
                                href="#"
                                @click="endSessionWithSuccess"
                                data-testid="end-call-with-success-button"
                            >End Call With Success</a>
                            <a
                                class="dropdown-item bg-light"
                                href="#"
                                @click="ejectApplicantFromSession"
                                data-testid="eject-applicant-button"
                                v-if="!customerIsConnectingToTwilioAV && sessionState !== SessionState.ejectApplicantFromCall"
                            >Eject Applicant From Call</a>
                            <hr>
                            <h6 class="dropdown-header">
                                Primary View Selection
                            </h6>
                            <a
                                v-if="isPrimaryApplicant"
                                class="dropdown-item"
                                :class="{ 'bg-warning': primaryView === PrimaryView.lastTransferDocument }"
                                href="#"
                                data-testid="primary-view-show-last-transfer-doc-button"
                                @click="clickButtonShowLastTransferDocument"
                            >Last Transfer Doc</a>
                            <a
                                class="dropdown-item"
                                :class="{ 'bg-warning': primaryView === PrimaryView.docuSign }"
                                href="#"
                                data-testid="primary-view-show-docusign-button"
                                @click="clickButtonShowDocuSign"
                            >DocuSign</a>
                            <a
                                class="dropdown-item"
                                :class="{ 'bg-warning': primaryView === PrimaryView.deedOfTrust }"
                                href="#"
                                @click="clickButtonShowDeedOfTrust"
                                v-if="sessionType === SessionType.RIN"
                            >Deed of Trust</a>
                            <a
                                class="dropdown-item"
                                :class="{ 'bg-warning': primaryView === PrimaryView.notarialCertificate }"
                                href="#"
                                @click="clickButtonShowNotarialCertificate"
                                v-if="sessionType === SessionType.RIN"
                            >Notarial Certificate</a>
                            <div class="dropdown-divider" />
                            <h6 class="dropdown-header">
                                Applicant View Selection
                            </h6>
                            <div>
                                <a
                                    class="dropdown-item"
                                    :class="{ 'bg-warning': sessionState === SessionState.viewingFaceVideos }"
                                    href="#"
                                    data-testid="applicant-view-show-video-feed-button"
                                    @click="clickButtonApplicantViewingVideoFeed"
                                >Viewing Video Feed</a>
                                <a
                                    class="dropdown-item"
                                    :class="{ 'bg-warning': sessionState === SessionState.performingFrontIdVerification || sessionState === SessionState.performingBackIdVerification }"
                                    href="#"
                                    data-testid="applicant-view-id-verification-button"
                                    @click="clickButtonShowIdVerificationMode"
                                >ID Verification</a>
                                <a
                                    v-show="sessionState === SessionState.performingFrontIdVerification || sessionState === SessionState.performingBackIdVerification"
                                    class="dropdown-item additional-item"
                                    href="#"
                                    :data-testid="
                                        sessionState === SessionState.performingFrontIdVerification ? 'applicant-view-take-front-id-picture-button' : 'applicant-view-take-back-id-picture-button'
                                    "
                                    @click="takeIDPicture"
                                >Take {{ sessionState === SessionState.performingFrontIdVerification ? 'Front' : 'Back' }} Picture</a>
                                <a
                                    class="dropdown-item"
                                    :class="{ 'bg-warning': sessionState === SessionState.viewingDocuSign }"
                                    href="#"
                                    data-testid="applicant-view-show-docusign-button"
                                    @click="clickButtonApplicantViewingDocusign"
                                >Viewing DocuSign</a>
                                <a
                                    class="dropdown-item"
                                    :class="{ 'bg-warning': sessionState === SessionState.viewingDeedOfTrust }"
                                    href="#"
                                    @click="clickButtonApplicantViewingDeedOfTrust"
                                    v-if="sessionType === SessionType.RIN"
                                >Viewing Deed of Trust</a>
                                <a
                                    class="dropdown-item"
                                    :class="{ 'bg-warning': [SessionState.gatherPlotterSignature].includes(sessionState) }"
                                    href="#"
                                    @click="clickButtonPlotterSigning"
                                    v-if="sessionType === SessionType.RIN"
                                >Plotter Signing</a>
                            </div>
                            <hr>
                            <h6 class="dropdown-header">
                                Sidebar View Toggle
                            </h6>
                            <a
                                v-show="isPrimaryApplicant"
                                class="dropdown-item"
                                href="#"
                                data-testid="sidebar-view-regenerate-docusign-button"
                                @click="clickButtonRegenerateDocusign"
                            >Re-generate Docusign</a>
                            <a
                                v-show="sessionType === SessionType.RIN"
                                class="dropdown-item"
                                :class="{ 'bg-warning': uiViewingState.isShowingScannedFileUpload }"
                                href="#"
                                @click="clickButtonShowScannedFileUpload"
                            >Upload Scanned Documents</a>
                            <a
                                class="dropdown-item"
                                :class="{ 'bg-warning': uiViewingState.isShowingIdVerificationReport }"
                                href="#"
                                data-testid="sidebar-view-id-verification-button"
                                @click="clickShowIdVerificationReport"
                            >ID Verification</a>
                            <div
                                class="dropdown-divider"
                                v-if="isPrimaryApplicant"
                            />
                            <h6
                                class="dropdown-header"
                                v-if="isPrimaryApplicant"
                            >
                                Applicant Updates
                            </h6>
                            <a
                                class="dropdown-item"
                                href="#"
                                @click="toggleUpdateApplicantFieldsModal"
                                v-if="isPrimaryApplicant"
                            >Update Applicant Data</a>
                            <div class="dropdown-divider" />
                            <h6 class="dropdown-header">
                                Session Failure
                            </h6>
                            <a
                                v-if="userInfo"
                                class="dropdown-item bg-danger"
                                href="#"
                                data-testid="end-call-with-failure-button"
                                @click="showFailureReasonModal(SessionState.sessionCompleteFailure, sessionType)"
                            >End With Failure</a>
                            <a
                                v-if="userInfo && isPrimaryApplicant === false"
                                class="dropdown-item bg-warning"
                                href="#"
                                data-testid="end-call-with-partial-failure-button"
                                @click="showFailureReasonModal(SessionState.sessionPartialFailure, sessionType)"
                            >End With Failure (Re-Notarize All)</a>
                            <h6 class="dropdown-header">
                                Fraud Detection
                            </h6>
                            <a
                                v-if="userInfo"
                                class="dropdown-item bg-danger"
                                href="#"
                                data-testid="fraud-flag-button"
                                @click="openFlagFraudModal()"
                            >Flag Account</a>
                        </div>
                        <div v-else>
                            <h6 class="dropdown-header">
                                Out of Session Actions
                            </h6>
                            <a
                                class="dropdown-item"
                                href="#"
                                v-if="notaryBusyStatus !== 'offline'"
                                @click="goOffline"
                            >Go Offline</a>
                            <a
                                class="dropdown-item"
                                href="#"
                                v-else
                                @click="goIdle"
                            >Go Online</a>
                            <div class="dropdown-divider" />
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <div class="content">
            <div
                v-show="false"
                style="width: 100%"
            >
                <canvas
                    id="hiddenCanvas"
                    ref="hiddenCanvas"
                    height="720px"
                    width="720px"
                />
            </div>
            <div class="left">
                <div class="scrollableContent p-3">
                    <div class="overflow-hidden rounded-lg">
                        <div class="videos">
                            <div
                                class="face-videos"
                                v-if="userInfo"
                            >
                                <div
                                    ref="twilioRemoteAudioVideo"
                                    id="customerStream"
                                    class="text-center"
                                    :class="{ 'p-5': !twilioRemoteParticipant }"
                                >
                                    <span
                                        v-if="!twilioRemoteParticipant"
                                        class="text-white"
                                    >customer is ready to<br>join video</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-show="userInfo && twilioRoom">
                        <p class="mb-1 text-center">
                            <span
                                class="small text-muted"
                                data-testid="applicant-video-call-network-quality"
                            > Quality {{ twilioRemoteParticipantNetworkQuality || '?' }}/5 </span>
                        </p>
                        <div>
                            <hr>
                            <div
                                class="toggled-item section-header"
                                v-show="sessionType === SessionType.RIN"
                            >
                                <span :class="{ toggled: uiViewingState.isShowingScannedFileUpload }">Scanned Documents</span>
                            </div>
                            <div class="toggled-item section-header">
                                <span :class="{ toggled: uiViewingState.isShowingIdVerificationReport }">ID Verification</span>
                            </div>
                            <div
                                v-show="uiViewingState.isShowingScannedFileUpload"
                                class="h-100"
                            >
                                <scanned-file-upload
                                    ref="scannedFileUpload"
                                    @on-upload-success="onScannedUploadSuccess"
                                />
                            </div>
                            <div class="mb-2">
                                <persona-pre-session-id-verification
                                    ref="personaPreSessionIdVerification"
                                    :applicant-id="applicantId"
                                    :loan-application-id="loanApplicationId"
                                />
                            </div>
                            <div
                                v-show="uiViewingState.isShowingIdVerificationReport"
                                class="h-100"
                            >
                                <persona-in-session-id-verification
                                    ref="personaInSessionIdVerification"
                                    :applicant-id="applicantId"
                                    :loan-application-id="loanApplicationId"
                                    :take-i-d-picture="takeIDPicture"
                                />
                            </div>
                        </div>
                    </div>
                    <div v-show="userInfo && !twilioRoom">
                        <h5>Next Customer</h5>
                        <p>
                            <span class="text-muted">Full Name: </span>
                            <span class="fw-bold">{{ `${userFirstName} ${userLastName}` }}</span>
                        </p>
                        <p>
                            <span class="text-muted">KBA Results: </span>
                            <span
                                class="fw-bold"
                                v-html="kbaStatus"
                            />
                        </p>
                    </div>
                    <div v-show="!userInfo">
                        <h5>No Customers Waiting</h5>
                    </div>
                    <div v-show="!userInfo">
                        <hr>
                        <h5>Notary Details</h5>
                        <span class="text-muted">Notary Status: </span>
                        <span class="fw-bold">{{ notaryBusyStatus }}</span>
                        <br>
                        <span class="text-muted">Queue Level: </span>
                        <span class="fw-bold">{{ notaryQueueLevel }}</span>
                        <br>
                        <span class="text-muted">Session Types: </span>
                        <span
                            class="fw-bold"
                            v-html="notaryAcceptedSessionTypes"
                        />
                    </div>
                    <hr>
                    <div class="overflow-hidden notary-video mt-2">
                        <div class="face-videos">
                            <span class="text-muted d-block mb-1">Notary Video:</span>
                            <div
                                ref="notaryVideo"
                                id="notaryVideo"
                                class="videos"
                            />
                            <span
                                class="text-muted mt-2 d-block mb-1"
                                v-if="sessionType !== SessionType.RON"
                            >Plotter Video:</span>
                            <div
                                ref="plotterVideo"
                                id="plotterVideo"
                                v-show="sessionType !== SessionType.RON"
                                class="videos"
                            />
                        </div>
                    </div>
                    <div
                        id="track-selection-container"
                        ref="trackSelectionContainer"
                    >
                        <div class="d-grid">
                            <button
                                class="btn"
                                :class="!uiViewingState.showVideoAudioSettings ? 'btn-light' : 'btn-dark'"
                                data-toggle="collapse"
                                @click="uiViewingState.showVideoAudioSettings = !uiViewingState.showVideoAudioSettings"
                            >
                                {{ !uiViewingState.showVideoAudioSettings ? 'A/V Settings' : 'Close' }}
                            </button>
                        </div>
                        <div
                            class="collapse pt-2"
                            :class="{ show: uiViewingState.showVideoAudioSettings }"
                        >
                            <span class="text-muted">Notary Video Device:</span>
                            <select v-model="selectedVideoDeviceId">
                                <option
                                    v-for="(videoDevice, index) in availableVideoDevices"
                                    :value="videoDevice.deviceId"
                                    :key="'notaryVideo' + videoDevice.deviceId"
                                >
                                    {{ videoDevice.label || 'Camera ' + index }}
                                </option>
                            </select>
                            <span
                                class="text-muted"
                                v-if="sessionType !== SessionType.RON"
                            >Plotter Video Device:</span>
                            <select
                                v-if="sessionType !== SessionType.RON"
                                v-model="selectedPlotterVideoDeviceId"
                            >
                                <option
                                    v-for="(videoDevice, index) in availableVideoDevices"
                                    :value="videoDevice.deviceId"
                                    :key="'plotterVideo' + videoDevice.deviceId"
                                >
                                    {{ videoDevice.label || 'Camera ' + index }}
                                </option>
                            </select>
                            <span class="text-muted">Notary Audio Device:</span>
                            <select v-model="selectedAudioDeviceId">
                                <option
                                    v-for="(audioDevice, index) in availableAudioDevices"
                                    :value="audioDevice.deviceId"
                                    :key="'faceAudio' + audioDevice.deviceId"
                                >
                                    {{ audioDevice.label || 'Audio ' + index }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="middle">
                <div
                    v-show="userInfo && notaryBusyStatus === NotaryBusyStatus.idle && !customerIsConnectingToTwilioAV"
                    class="h-100"
                >
                    <div class="h-100 d-flex justify-content-center align-items-center text-center">
                        <div>
                            <h5>1 Person Waiting</h5>
                            <button
                                class="btn btn-primary"
                                data-testid="start-next-notary-customer-button"
                                @click="nextCustomer"
                            >
                                Start Next Customer
                            </button>
                        </div>
                    </div>
                </div>
                <div
                    v-show="twilioRoom"
                    class="document-container"
                >
                    <last-transfer-document
                        v-if="isPrimaryApplicant && userInfo && applicantNotaryAssignmentId && primaryView === PrimaryView.lastTransferDocument"
                        ref="lastTransferDocument"
                        :applicant-id="applicantId"
                        :loan-application-id="loanApplicationId"
                        :applicant-notary-assignment-id="applicantNotaryAssignmentId"
                    />
                    <!-- We specifically want to destroy and recreate the docusign component based on session status -->
                    <docusign
                        v-if="userInfo"
                        v-show="userInfo && primaryView === PrimaryView.docuSign"
                        ref="docuSign"
                        :agent="SessionAgent.notary"
                        :applicant-id="applicantId"
                        :loan-application-id="loanApplicationId"
                    />
                    <deed-of-trust
                        v-if="userInfo && primaryView === PrimaryView.deedOfTrust"
                        ref="deedOfTrust"
                        :applicant-id="applicantId"
                        :loan-application-id="loanApplicationId"
                        :agent="SessionAgent.notary"
                    />
                    <notarial-certificate
                        v-if="userInfo && primaryView === PrimaryView.notarialCertificate"
                        ref="notarialCertificate"
                        :applicant-id="applicantId"
                        :loan-application-id="loanApplicationId"
                    />
                </div>
            </div>
            <div class="right">
                <div
                    class="outerDivWrapper"
                    :class="{ 'hide-right-pane': !uiViewingState.showRightPane }"
                >
                    <div class="position-relative">
                        <div
                            class="position-absolute pt-2 px-2 right-0"
                            :class="{ 'h-100 text-center': !uiViewingState.showRightPane }"
                        >
                            <button
                                class="border-0 bg-transparent text-primary"
                                @click="uiViewingState.showRightPane = !uiViewingState.showRightPane"
                            >
                                <img
                                    v-show="uiViewingState.showRightPane"
                                    src="@/assets/images/global/icon-open-pane.svg"
                                    alt="Close"
                                >
                                <img
                                    v-show="!uiViewingState.showRightPane"
                                    src="@/assets/images/global/icon-close-pane.svg"
                                    alt="Open"
                                >
                            </button>
                        </div>
                    </div>
                    <div class="outerDiv">
                        <div class="scrollableContent p-3">
                            <div class="d-flex justify-content-between flex-column mb-6">
                                <div :class="{ 'd-none': !uiViewingState.showRightPane }">
                                    <h5 class="me-5 pe-5">
                                        Checklist
                                    </h5>
                                    <form-checkbox-group
                                        data-testid="notary-checklist"
                                        :key="!!userInfo"
                                        class="mb-5"
                                        group-name="checklist"
                                        validation="required"
                                        v-model="checkboxOptions"
                                        :initial-value="initialCheckboxOptions"
                                    />
                                </div>
                                <div :class="{ 'd-none': !uiViewingState.showRightPane }">
                                    <h5 class="me-5 pe-5">
                                        Additional Info
                                    </h5>
                                    <div>
                                        <p class="mb-0 text-muted">
                                            All Previous Personal Addresses:
                                            <span v-if="allPreviousPersonalAddresses.length === 0"> N/A</span>
                                            <span v-else>
                                                <span
                                                    v-for="(address, index) in allPreviousPersonalAddresses"
                                                    :key="index"
                                                    class="fw-bold"
                                                >
                                                    <li>{{ address }}</li>
                                                </span>
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <update-applicant-fields-modal
            v-if="userInfo && applicantNotaryAssignmentId && allApplicantsUpdatableInfo"
            :show="showUpdateApplicantFieldsModal"
            :applicants-info="allApplicantsUpdatableInfo"
            :primary-applicant-notary-assignment-id="applicantNotaryAssignmentId"
            @request-hide-modal="showUpdateApplicantFieldsModal = false"
            @successful-update="fetchInSessionApplicant"
        />
        <flag-fraud-account-modal
            :show="!!uiViewingState.isFraudModalOpen"
            @close="closeFlagFraudModal"
            @submit="flagFraudAccount($event)"
        />
        <set-referral-source-modal
            :show="!!uiViewingState.isReferralSourceModalOpen"
            @close="closeReferralSourceModal"
            @submit="submitReferralSource($event)"
        />
        <failure-reason-modal
            v-if="uiViewingState.failureReasonModalState.sessionState"
            :show="!!uiViewingState.failureReasonModalState.open"
            :modal-state="uiViewingState.failureReasonModalState"
            @close="closeFailureReasonModal"
            @submit="submitFailureReason"
        />
    </notary-layout>
</template>

<script>
    import NotaryLayout from '@/layouts/remoteNotarization/Notary'
    import FormCheckboxGroup from '@/components/base/FormCheckboxGroup'
    import { connect, createLocalTracks } from 'twilio-video'
    import { logger } from '@/utils/logger'
    import {
        attachTrack,
        SessionAgent,
        SessionState,
        toProperCase,
        NOTARY_VIDEO_PREFIX,
        PLOTTER_VIDEO_PREFIX,
        NOTARY_AUDIO_PREFIX,
        ApplicantType,
        MAX_TRACK_PUBLISH_TIME_MSEC,
    } from '@/utils/remoteNotarization'
    import { appSessionStorage, localStorageKey } from '@/utils/storage'
    import assert from 'assert'
    import {
        applicantConnected,
        applicantDisconnected,
        beginRemoteNotarizationSession,
        checkForInProgressRoom,
        endRemoteNotarizationSession,
        getNotary,
        getInSessionApplicant,
        getNotaryStatus,
        setBackendSessionState,
        updateNotaryBusyStatus,
        FlowStatus,
        KbaStatus,
        NotaryBusyStatus,
        SessionType,
        setReferralSource,
        getDocuSignEnvelopeSigningStatus,
        regenerateDocusign,
    } from '@/services/remoteNotarizationApi'
    import { postUpdateFraudFlag, ResidenceType } from '@/services/api'
    import { notaryPagePaths } from '@/routes/notaryRoutes'
    import DocuSign from '@/components/remoteNotarization/DocuSign'
    import LastTransferDocument from '@/components/remoteNotarization/LastTransferDocument'
    import PersonaInSessionIdVerification from '@/components/remoteNotarization/PersonaInSessionIdVerification'
    import { isEqual, toUpper } from 'lodash'
    import DeedOfTrust from '@/components/remoteNotarization/DeedOfTrust'
    import UpdateApplicantFieldsModal from '@/components/UpdateApplicantFieldsModal'
    import { awaitWithTimeout } from '@/utils/asyncTools'
    import ScannedFileUpload from '@/components/remoteNotarization/ScannedFileUpload'
    import FlagFraudAccountModal from '@/components/FlagFraudAccountModal'
    import NotarialCertificate from '@/components/remoteNotarization/NotarialCertificate'
    import { convertDateTimeFormat } from '@/utils/date'
    import SetReferralSourceModal from '@/components/SetReferralSourceModal'
    import FailureReasonModal from '@/components/FailureReasonModal'
    import generic from '@/utils/generic'
    import { AutoFraudFlagReasons, systemFraudFlagsHelper } from '@/utils/systemFraudFlagsHelper'
    import { fireAndForget } from '@/utils/fireAndForget'
    import { inspect } from '@/utils/inspect'
    import { NotaryApiErrorHandler } from '@/utils/exception-handler'
    import { DateTime } from 'luxon'
    import PersonaPreSessionIdVerification from '@/components/remoteNotarization/PersonaPreSessionIdVerification'

    const PrimaryView = {
        docuSign: 'docuSign',
        lastTransferDocument: 'lastTransferDocument',
        // RIN Only Modes
        deedOfTrust: 'deedOfTrust',
        notarialCertificate: 'notarialCertificate',
    }

    export default {
        components: {
            PersonaPreSessionIdVerification,
            FailureReasonModal,
            FormCheckboxGroup,
            LastTransferDocument,
            docusign: DocuSign,
            NotaryLayout,
            PersonaInSessionIdVerification,
            UpdateApplicantFieldsModal,
            FlagFraudAccountModal,
            // This is only used for RIN sessions
            ScannedFileUpload,
            DeedOfTrust,
            NotarialCertificate,
            SetReferralSourceModal,
        },
        data() {
            return {
                // Passing various enums / funcs to template code
                ApplicantType,
                SessionState,
                SessionAgent,
                NotaryBusyStatus,
                FlowStatus,
                KbaStatus,
                SessionType,
                PrimaryView,

                // Data about us
                notaryStatus: null,
                notary: null,

                // Our local twilio tracks and config
                twilioRoom: null,
                twilioAccessToken: null,
                localVideoTrack: null,
                localVideoTrackElem: null,
                // The plotterVideoTrack/plotterVideoTrackElem vars are only set for RIN modes
                plotterVideoTrack: null,
                plotterVideoTrackElem: null,
                localAudioTrack: null,
                // note: no localAudioTrackElem b/c we don't want to listen to ourselves

                // Network stats and quality
                networkStatsLogIntervalId: null,
                networkStatsLogIntervalMsec: 10 * 1000,
                notaryNetworkQuality: 0,
                twilioRemoteParticipantNetworkQuality: 0,

                // Polling for new applicants from backend
                pollForInSessionApplicantIntervalId: null,
                pollForInSessionApplicantIntervalMsec: Number(appSessionStorage.getItem(localStorageKey.pollInterval) ?? 1000),

                // User info
                applicantNotaryAssignmentId: null,
                userInfo: null,

                // Twilio remote participant tracks
                twilioRemoteParticipant: null,
                twilioRemoteVideoTrack: null,
                twilioRemoteVideoTrackElem: null,
                twilioRemoteAudioTrack: null,
                twilioRemoteAudioTrackElem: null,

                // Polling for session state data from backend
                pollNotaryStatusIntervalId: null,
                pollNotaryStatusIntervalInMs: Number(appSessionStorage.getItem(localStorageKey.pollInterval) ?? 1000),

                // Selecting AV devices locally
                selectedVideoDeviceId: null,
                selectedPlotterVideoDeviceId: null,
                selectedAudioDeviceId: null,
                availableVideoDevices: null,
                availableAudioDevices: null,

                autoLogoutIntervalId: null,
                autoLogoutLastActivity: Date.now(),
                // Log out after this long if no user activity is detected
                // 30min for prod, 24 hours for all other env modes
                autoLogoutTimeoutMsec: process.env.VUE_APP_NODE_ENV === 'production' ? 30 * 60 * 1000 : 24 * 60 * 60 * 1000,

                // Updating minutes remaining timer
                pollTimeRemainingIntervalId: null,
                // this is a luxon duration object
                timeRemainingDuration: null,
                // Take the expected session duration and divide it by this number (this is what we show to the user
                // Ex: 30min / sessionScheduledDurationAdjustmentFactor of 2 -> 15min
                sessionScheduledDurationAdjustmentFactor: 1,

                // UI related things
                primaryView: PrimaryView.docuSign,
                checkboxOptions: {},
                uiViewingState: {
                    hasShownDeedOfTrust: false,
                    showActions: false,
                    showVideoAudioSettings: false,
                    showRightPane: true,
                    isShowingIdVerificationReport: false,
                    isShowingScannedFileUpload: false,
                    isFraudModalOpen: false,
                    isReferralSourceModalOpen: false,
                    failureReasonModalState: {
                        open: false,
                        sessionState: null,
                        sessionType: null,
                    },
                    // Backing field for computed property this.errorText
                    _errorText: '',
                },
                showUpdateApplicantFieldsModal: false,
            }
        },
        computed: {
            // We need these b/c optional accessors are not supported in templates in vuejs v2
            // https://github.com/vuejs/vue/issues/11088
            applicantId: function () {
                return this.userInfo?.applicantId
            },
            loanApplicationId: function () {
                return this.userInfo?.loanApplicationId
            },
            userFirstName: function () {
                return toUpper(this.userInfo?.firstName)
            },
            userLastName: function () {
                return toUpper(this.userInfo?.lastName)
            },
            userMaritalStatus: function () {
                return toUpper(this.userInfo?.maritalStatus)
            },
            sessionStartTime() {
                return this.userInfo?.sessionStartTime ? new Date(this.userInfo.sessionStartTime) : null
            },
            sessionScheduledDurationMin() {
                return this.userInfo?.sessionScheduledDurationMin
            },
            batchName: function () {
                return this.userInfo?.batchName
            },
            userDateOfBirth: function () {
                // These get serialized to strings, despite being Date objects in aven-backend
                return this.userInfo?.dateOfBirth ? new Date(this.userInfo.dateOfBirth) : null
            },
            userDateOfBirthFormatted: function () {
                // We want to keep the current TZ as UTC (database time) so as to avoid rounding someone's bday up or down a day
                return this.userDateOfBirth ? convertDateTimeFormat(this.userDateOfBirth, 'UTC', 'UTC', 'MM/DD/YYYY') : 'Unknown'
            },
            propertyAddressFormatted: function () {
                const propertyAddress = this.userInfo?.homeAddress
                return `${propertyAddress.address}, ${propertyAddress.city}, ${propertyAddress.state}`
            },
            personalAddressFormatted: function () {
                const personalAddress = this.userInfo?.personalAddress
                return `${personalAddress.personalAddressStreet}, ${personalAddress.personalAddressCity}, ${personalAddress.personalAddressState} ${personalAddress.personalAddressZip}`
            },
            sessionType: function () {
                return this.userInfo?.sessionType
            },
            livingTrustName: function () {
                return toProperCase(this.userInfo?.livingTrustName)
            },
            allApplicantsUpdatableInfo: function () {
                return this.userInfo?.allApplicantsUpdatableInfo
            },
            livingTrustVestingClause: function () {
                return this.userInfo?.livingTrustVestingClause
            },
            homeOwnerNames: function () {
                return this.userInfo?.homeOwnerNames?.join(', ')
            },
            apn: function () {
                return this.userInfo?.apn
            },
            homeResidenceType: function () {
                return this.userInfo?.homeResidenceType
            },
            allPreviousPersonalAddresses: function () {
                return this.userInfo?.allPreviousPersonalAddresses || []
            },
            primaryApplicant: function () {
                return this.allApplicantsUpdatableInfo?.filter((x) => [ApplicantType.primary].includes(x.type))?.[0]
            },
            isPrimaryApplicant: function () {
                return this.applicantId === this.primaryApplicant?.id
            },
            secondaryApplicant: function () {
                return this.allApplicantsUpdatableInfo?.filter((x) => [ApplicantType.coApplicant, ApplicantType.coOwner].includes(x.type))?.[0]
            },
            otherApplicants: function () {
                return this.allApplicantsUpdatableInfo
                    ?.filter((x) => x.id !== this.applicantId)
                    .map((x) => ({
                        name: toUpper(x.firstName) + ' ' + toUpper(x.lastName),
                        type: toUpper(x.type),
                        phone: generic.formatPhoneNumber(x.phoneNumber),
                    }))
            },
            kbaStatus: function () {
                if (this.userInfo?.kbaStatus === KbaStatus.incomplete) {
                    return `KBA NOT YET COMPLETED`
                }
                if ([KbaStatus.allowableFail, KbaStatus.override].includes(this.userInfo?.kbaStatus)) {
                    return `<span class="text-danger">VERIFY TWO FORMS OF ID</span>`
                }
                if (this.userInfo?.kbaStatus === KbaStatus.verifySSN) {
                    return `<span class="text-danger">VERIFY TWO FORMS OF ID + SSN CARD</span>`
                }
                if (this.userInfo?.kbaStatus === KbaStatus.fail) {
                    return `<span class="text-danger">FAIL</span>`
                }
                if (this.userInfo?.kbaStatus === KbaStatus.permanentFail) {
                    return `<span class="text-danger">48 HOUR KBA BAN</span>`
                }
                return toProperCase(this.userInfo?.kbaStatus)
            },
            applicantChangedNameFrom: function () {
                return this.userInfo?.applicantChangedNameFrom
            },
            attestedTitleName: function () {
                return this.userInfo?.attestedTitleName
            },
            sessionState: function () {
                return this.notaryStatus?.sessionState
            },
            plotterStatus: function () {
                return this.notaryStatus?.plotterReady ? 'Connected' : 'Disconnected'
            },
            notaryBusyStatus: function () {
                return this.notaryStatus?.notaryBusyStatus || NotaryBusyStatus.error
            },
            notaryQueueLevel: function () {
                return this.notaryStatus?.queueLevel ? `L${this.notaryStatus?.queueLevel}` : 'N/A'
            },
            notaryAcceptedSessionTypes: function () {
                const acceptedSessionTypes = this.notaryStatus?.acceptedSessionTypes
                if (!acceptedSessionTypes) return 'N/A'
                if (!acceptedSessionTypes.length) return '<span class="text-danger">DISABLED</span>'
                return acceptedSessionTypes.join(' / ')
            },
            notaryName: function () {
                return this.notary?.name || 'Notary'
            },
            customerIsConnectingToTwilioAV() {
                return !!(this.userInfo && this.twilioRoom && !this.twilioRemoteParticipant)
            },
            errorText: {
                get() {
                    if (this.twilioRoom && !this.twilioRemoteParticipant) return 'Customer has disconnected. Awaiting reconnection.'
                    return this.uiViewingState._errorText
                },
                set(value) {
                    this.uiViewingState._errorText = value
                },
            },
            isReadyToStartTwilioRoom() {
                // !this.twilioRoom is necessary to prevent us setting the twilioRoom more than once
                const value = !!this.localVideoTrack && (this.sessionType === SessionType.RON || !!this.plotterVideoTrack) && !!this.localAudioTrack && !!this.twilioAccessToken && !this.twilioRoom

                logger.log(`Calculating isReadyToStartTwilioRoom:
                this.localVideoTrack: ${!!this.localVideoTrack}
                && (this.sessionType === SessionType.RON: ${this.sessionType === SessionType.RON} || this.plotterVideoTrack: ${!!this.plotterVideoTrack}): ${
                    this.sessionType === SessionType.RON || !!this.plotterVideoTrack
                }
                && this.localAudioTrack: ${!!this.localAudioTrack}
                && this.twilioAccessToken: ${!!this.twilioAccessToken}
                && !this.twilioRoom: ${!this.twilioRoom}`)

                return value
            },
            initialCheckboxOptions() {
                // Only ask for referral source from the primary applicant
                let options
                if (this.notaryBusyStatus === NotaryBusyStatus.busy && this.sessionType === SessionType.RON) {
                    options = {
                        'Zoom Recording': { value: this.checkboxOptions?.['Zoom Recording']?.value ?? false },
                        'Verify ID in Persona': { value: this.checkboxOptions?.['Verify ID in Persona']?.value ?? false },
                        'Verify ID Live': {
                            value: !!this.$refs?.personaInSessionIdVerification?.isPassed,
                            userEditable: !!this.$refs?.personaInSessionIdVerification?.backIDPhotoUrl && !this.$refs?.personaInSessionIdVerification?.isPassed,
                        },
                        'Notify about the Second ID': {
                            value: this.checkboxOptions?.['Notify about the Second ID']?.value ?? false,
                            enabled: [KbaStatus.allowableFail, KbaStatus.override, KbaStatus.verifySSN].includes(this.userInfo?.kbaStatus),
                        },
                        'Ask about previous addresses': { value: this.checkboxOptions?.['Ask about previous addresses']?.value ?? false },
                        'Ask personal KBA questions': { value: this.checkboxOptions?.['Ask personal KBA questions']?.value ?? false },
                        'Verify LTD ownership': { value: this.checkboxOptions?.['Verify LTD ownership']?.value ?? false },
                        'Confirm Secondary Signer Name Matches ID Exactly': {
                            value: this.checkboxOptions?.['Confirm Secondary Signer Name Matches ID Exactly']?.value ?? false,
                            enabled: !!this.secondaryApplicant,
                        },
                        'Verify Living Trust Information': { enabled: !!this.livingTrustName, value: false },
                        'Applicant Views & Signs DocuSign': { value: this.$refs?.docuSign?.state?.envelopeId && this.$refs?.docuSign?.state?.hasApplicantSigned, userEditable: false },
                        'Document Review': { value: this.checkboxOptions?.['Document Review']?.value ?? false },
                        'Verify APN': { value: this.checkboxOptions?.['Verify APN']?.value ?? false },
                        'Notary Acknowledgment': { value: this.checkboxOptions?.['Notary Acknowledgment']?.value ?? false },
                        'Sign and Stamp': { value: this.$refs?.docuSign?.state?.hasNotaryStamped, userEditable: false },
                        'Final Declaration': { value: this.checkboxOptions?.['Final Declaration']?.value ?? false },
                    }
                } else if (this.notaryBusyStatus === NotaryBusyStatus.busy && this.sessionType === SessionType.RIN) {
                    options = {
                        'Zoom Recording': { value: this.checkboxOptions?.['Zoom Recording']?.value ?? false },
                        'Verify ID in Persona': { value: this.checkboxOptions?.['Verify ID in Persona']?.value ?? false },
                        'Verify ID Live': {
                            value: !!this.$refs?.personaInSessionIdVerification?.isPassed,
                            userEditable: !!this.$refs?.personaInSessionIdVerification?.backIDPhotoUrl && !this.$refs?.personaInSessionIdVerification?.isPassed,
                        },
                        'Notify about the Second ID': {
                            value: this.checkboxOptions?.['Notify about the Second ID']?.value ?? false,
                            enabled: [KbaStatus.allowableFail, KbaStatus.override, KbaStatus.verifySSN].includes(this.userInfo?.kbaStatus),
                        },
                        'Ask about previous addresses': { value: this.checkboxOptions?.['Ask about previous addresses']?.value ?? false },
                        'Ask personal KBA questions': { value: this.checkboxOptions?.['Ask personal KBA questions']?.value ?? false },
                        'Verify LTD ownership': { value: this.checkboxOptions?.['Verify LTD ownership']?.value ?? false },
                        'Confirm Secondary Signer Name Matches ID Exactly': {
                            value: this.checkboxOptions?.['Confirm Secondary Signer Name Matches ID Exactly']?.value ?? false,
                            enabled: !!this.secondaryApplicant,
                        },
                        'Applicant Views & Signs DocuSign': { value: this.$refs?.docuSign?.state?.envelopeId && this.$refs?.docuSign?.state?.hasApplicantSigned, userEditable: false },
                        'Acknowledge DocuSign': { value: this.$refs?.docuSign?.state?.hasNotaryStamped, userEditable: false },
                        'Show Applicant Deed of Trust': { userEditable: false, value: !!this.uiViewingState.hasShownDeedOfTrust },
                        'Print Deed of Trust': { userEditable: false, value: !!this.$refs?.deedOfTrust?.deedOfTrustBlobUrl },
                        'Sign Deed of Trust': { value: this.checkboxOptions?.['Sign Deed of Trust']?.value ?? false },
                        'Signature Confirmation': { value: this.checkboxOptions?.['Signature Confirmation']?.value ?? false },
                        'Document Review': { value: this.checkboxOptions?.['Document Review']?.value ?? false },
                        'Verify APN': { value: this.checkboxOptions?.['Verify APN']?.value ?? false },
                        'Notary Acknowledgment': { value: this.checkboxOptions?.['Notary Acknowledgment']?.value ?? false },
                        'Final Declaration': { value: this.checkboxOptions?.['Final Declaration']?.value ?? false },
                        'Upload scanned documents': { value: this.$refs?.scannedFileUpload?.hasUploadCompleted, userEditable: false },
                    }
                } else {
                    options = {
                        'Jus Chillin': { value: true, userEditable: false },
                    }
                }

                if (this.notaryBusyStatus === NotaryBusyStatus.busy && this.isPrimaryApplicant) {
                    options['Determine Referral Source'] = { value: this.checkboxOptions?.['Determine Referral Source']?.value ?? false }
                }

                return options
            },
        },
        watch: {
            sessionStartTime: function (newVal, oldVal) {
                if (isEqual(newVal, oldVal)) {
                    return
                }

                if (newVal) {
                    this.pollTimeRemaining()
                    logger.info(`Updated sessionStartTime from ${oldVal} to ${newVal}, starting poller for time remaining`)
                } else {
                    window.clearInterval(this.pollTimeRemainingIntervalId)
                    this.timeRemainingDuration = null
                    logger.info(`Updated sessionStartTime from ${oldVal} to ${newVal}, clearing poller for time remaining`)
                }
            },
            applicantId: function (newApplicantId) {
                if (newApplicantId) {
                    appSessionStorage.setItem(localStorageKey.applicantId, newApplicantId.toString())
                } else {
                    appSessionStorage.removeItem(localStorageKey.applicantId)
                }
            },
            loanApplicationId: function (newLoanApplicationId) {
                if (newLoanApplicationId) {
                    appSessionStorage.setItem(localStorageKey.loanApplicationId, newLoanApplicationId.toString())
                } else {
                    appSessionStorage.removeItem(localStorageKey.loanApplicationId)
                }
            },
            applicantNotaryAssignmentId: function (newApplicantNotaryAssignmentId) {
                if (newApplicantNotaryAssignmentId) {
                    appSessionStorage.setItem(localStorageKey.applicantNotaryAssignmentId, newApplicantNotaryAssignmentId.toString())
                } else {
                    appSessionStorage.removeItem(localStorageKey.applicantNotaryAssignmentId)
                }
            },
            notaryStatus: function (newNotaryStatus, oldNotaryStatus) {
                if (isEqual(newNotaryStatus, oldNotaryStatus)) {
                    return
                }

                logger.info(`Updated notaryStatus from ${JSON.stringify(oldNotaryStatus)} to ${JSON.stringify(newNotaryStatus)}`)
            },
            notaryBusyStatus: function (newNotaryBusyStatus, oldNotaryBusyStatus) {
                logger.info(`Updated notaryStatus from ${oldNotaryBusyStatus} to ${newNotaryBusyStatus}`)

                if ([NotaryBusyStatus.idle, NotaryBusyStatus.busy].includes(newNotaryBusyStatus) && !this.pollForInSessionApplicantIntervalId) {
                    logger.log("Looks like we're idle or busy but without pollForInSessionApplicantIntervalId, starting up polling for inSessionApplicant")
                    this.pollForInSessionApplicantIntervalId = setInterval(this.fetchInSessionApplicant, this.pollForInSessionApplicantIntervalMsec)
                } else if (![NotaryBusyStatus.idle, NotaryBusyStatus.busy].includes(newNotaryBusyStatus) && this.pollForInSessionApplicantIntervalId) {
                    logger.log('Canceling existing polling for inSessionApplicant')
                    window.clearInterval(this.pollForInSessionApplicantIntervalId)
                    this.pollForInSessionApplicantIntervalId = null
                }
            },
            primaryView: function (primaryView) {
                this.$logEvent('event_notary_switching_viewing_state', { to: primaryView })
            },
            selectedVideoDeviceId: async function (newVideoDeviceId, oldVideoDeviceId) {
                logger.info(`Updated selectedVideoDeviceId from ${oldVideoDeviceId} to ${newVideoDeviceId}`)
                this.localVideoTrack = (
                    await createLocalTracks({
                        video: {
                            name: `${NOTARY_VIDEO_PREFIX}${newVideoDeviceId}`,
                            deviceId: newVideoDeviceId,
                        },
                    })
                )[0]
                logger.info(`this.localVideoTrack updated!`)
            },
            selectedPlotterVideoDeviceId: async function (newPlotterVideoDeviceId, oldPlotterVideoDeviceId) {
                logger.info(`Updated selectedPlotterVideoDeviceId from ${oldPlotterVideoDeviceId} to ${newPlotterVideoDeviceId}`)
                this.plotterVideoTrack = (
                    await createLocalTracks({
                        video: {
                            name: `${PLOTTER_VIDEO_PREFIX}${newPlotterVideoDeviceId}`,
                            deviceId: newPlotterVideoDeviceId,
                        },
                    })
                )[0]
                logger.info(`this.plotterVideoTrack updated!`)
            },
            selectedAudioDeviceId: async function (newAudioDeviceId, oldAudioDeviceId) {
                logger.info(`Updated selectedAudioDeviceId from ${oldAudioDeviceId} to ${newAudioDeviceId}`)
                this.localAudioTrack = (
                    await createLocalTracks({
                        audio: {
                            name: `${NOTARY_AUDIO_PREFIX}${newAudioDeviceId}`,
                            deviceId: newAudioDeviceId,
                        },
                    })
                )[0]
                logger.info(`this.localAudioTrack updated!`)
            },
            localVideoTrack: async function (newVideoTrack, oldVideoTrack) {
                logger.info(`Updated localVideoTrack from ${oldVideoTrack} to ${newVideoTrack}`)

                if (this.localVideoTrackElem) {
                    logger.log(`Unmounting localVideoTrack`)
                    // This is what allows our lovely notary gets to see the new track
                    this.localVideoTrackElem.remove()
                }

                if (oldVideoTrack) {
                    logger.log(`Attempting to stop ${oldVideoTrack} before acting on ${newVideoTrack}`)
                    oldVideoTrack.stop()
                }

                if (newVideoTrack) {
                    logger.log(`Attaching newVideoTrack to DOM @ ${this.$refs.notaryVideo?.id}`)
                    this.localVideoTrackElem = attachTrack(newVideoTrack, this.$refs.notaryVideo)
                }

                // If twilioRoom and/or newVideoTrack is null, no need to publish / un-publish tracks and the sort
                if (!this.twilioRoom || !newVideoTrack) {
                    logger.log('this.twilioRoom and/or newVideoTrack is unset, skipping handler')
                    return
                }

                // Don't try to un-publish null values
                if (oldVideoTrack) {
                    logger.info(`Room is active, un-publishing ${oldVideoTrack}`)
                    this.twilioRoom.localParticipant.unpublishTrack(oldVideoTrack)
                }

                // Don't try to publish null values
                if (newVideoTrack) {
                    logger.info(`Room is active, publishing ${newVideoTrack}`)
                    // https://github.com/twilio/twilio-video.js/issues/963#issuecomment-795819739
                    await awaitWithTimeout(this.twilioRoom.localParticipant.publishTrack(newVideoTrack), MAX_TRACK_PUBLISH_TIME_MSEC)
                }
            },
            plotterVideoTrack: async function (newPlotterVideoTrack, oldPlotterVideoTrack) {
                logger.info(`Updated plotterVideoTrack from ${oldPlotterVideoTrack} to ${newPlotterVideoTrack}`)

                if (this.plotterVideoTrackElem) {
                    logger.log(`Unmounting plotterVideoTrack`)
                    // This is what allows our lovely notary gets to see the new track
                    this.plotterVideoTrackElem.remove()
                }

                if (oldPlotterVideoTrack) {
                    logger.log(`Attempting to stop ${oldPlotterVideoTrack} before acting on ${newPlotterVideoTrack}`)
                    oldPlotterVideoTrack.stop()
                }

                if (newPlotterVideoTrack) {
                    logger.log(`Attaching newPlotterVideoTrack to DOM @ ${this.$refs.plotterVideo?.id}`)
                    this.plotterVideoTrackElem = attachTrack(newPlotterVideoTrack, this.$refs.plotterVideo)
                }

                // If twilioRoom and/or newPlotterVideoTrack is null, no need to publish / un-publish tracks and the sort
                if (!this.twilioRoom || !newPlotterVideoTrack) {
                    logger.log('this.twilioRoom and/or newPlotterVideoTrack is unset, skipping handler')
                    return
                }

                // Don't try to un-publish null values
                if (oldPlotterVideoTrack) {
                    logger.info(`Room is active, un-publishing ${oldPlotterVideoTrack}`)
                    this.twilioRoom.localParticipant.unpublishTrack(oldPlotterVideoTrack)
                }

                // Don't try to publish null values
                if (newPlotterVideoTrack) {
                    logger.info(`Room is active, publishing ${newPlotterVideoTrack}`)
                    // https://github.com/twilio/twilio-video.js/issues/963#issuecomment-795819739
                    await awaitWithTimeout(this.twilioRoom.localParticipant.publishTrack(newPlotterVideoTrack), MAX_TRACK_PUBLISH_TIME_MSEC)
                }
            },
            localAudioTrack: async function (newAudioTrack, oldAudioTrack) {
                logger.info(`Updated localAudioTrack from ${oldAudioTrack} to ${newAudioTrack}`)

                if (oldAudioTrack) {
                    logger.log(`Attempting to stop ${oldAudioTrack} before acting on ${newAudioTrack}`)
                    oldAudioTrack.stop()
                }

                // If twilioRoom and/or newAudioTrack is null, no need to publish / un-publish tracks and the sort
                if (!this.twilioRoom || !newAudioTrack) {
                    logger.log('this.twilioRoom and/or newAudioTrack is unset, skipping handler')
                    return
                }

                // Don't try to un-publish null values
                if (oldAudioTrack) {
                    logger.info(`Room is active, un-publishing and stopping ${oldAudioTrack}`)
                    this.twilioRoom.localParticipant.unpublishTrack(oldAudioTrack)
                }

                // Don't try to publish null values
                if (newAudioTrack) {
                    logger.info(`Room is active, publishing ${newAudioTrack}`)
                    // https://github.com/twilio/twilio-video.js/issues/963#issuecomment-795819739
                    await awaitWithTimeout(this.twilioRoom.localParticipant.publishTrack(newAudioTrack), MAX_TRACK_PUBLISH_TIME_MSEC)
                }
            },
            twilioRoom: async function (newTwilioRoom, oldTwilioRoom) {
                logger.info(`Updated twilioRoom from ${oldTwilioRoom} to ${newTwilioRoom}`)

                if (oldTwilioRoom) {
                    logger.log('Disconnect from old twilio room before making changes')
                    clearInterval(this.networkStatsLogIntervalId)
                    oldTwilioRoom.disconnect()
                }

                // If newTwilioRoom is null, no need to attach handlers and the sort
                if (!newTwilioRoom) {
                    logger.log('Clearing existing credentials for null twilio room to prevent accidental reconnect')
                    this.twilioAccessToken = null
                    return
                }

                logger.log('Attaching handlers for twilioRoom')

                // We need to attach tracks for the other side if the client is in the twilioRoom before we are
                // Indeed, we need this fancy syntax to convert from a <String: RemoteParticipant> map to just the RP
                const existingParticipant = [...newTwilioRoom.participants.values()][0]
                if (existingParticipant) {
                    this.twilioRemoteParticipant = existingParticipant
                    logger.info(`A remote participant is already connected: ${existingParticipant.identity}.`)
                } else {
                    logger.info('Looks like there are no existing remote participants')
                }

                // We also want to attach listeners in case the client is not yet here
                newTwilioRoom.on('participantConnected', async (participant) => {
                    this.twilioRemoteParticipant = participant
                    logger.info(`A new remote participant connected: ${participant.identity}.`)
                })

                // In case the client disconnects, log it and remove their tracks
                newTwilioRoom.on('participantDisconnected', async (participant) => {
                    this.twilioRemoteParticipant = null
                    logger.info(`A remote participant disconnected: ${participant.identity}.`)
                })

                // In case we disconnect from the twilioRoom, log it
                newTwilioRoom.on('disconnected', (room, error) => {
                    // For more details on error codes:
                    // https://www.twilio.com/docs/video/build-js-video-application-recommendations-and-best-practices#handling-errors
                    // TODO: What do we do about this?
                    logger.info(`Twilio room has disconnected: ${error}`)
                })

                // Prime the network quality level, then attach the handler
                // This measures /our/ network quality, as reported by twilio
                this.notaryNetworkQuality = this.twilioRoom.localParticipant.networkQualityLevel
                newTwilioRoom.localParticipant.on('networkQualityLevelChanged', (networkQualityLevel) => (this.notaryNetworkQuality = networkQualityLevel))

                this.networkStatsLogIntervalId = setInterval(this.logTwilioNetworkStats, this.networkStatsLogIntervalMsec)
            },
            twilioRemoteParticipant: async function (newTwilioRemoteParticipant, oldTwilioRemoteParticipant) {
                logger.info(`Updated twilioRemoteParticipant from ${oldTwilioRemoteParticipant} to ${newTwilioRemoteParticipant}`)

                // Note we explicitly do not remove existing tracks if newTwilioRemoteParticipant is null
                // We're relying on the handlers below to properly clean up the tracks
                if (!newTwilioRemoteParticipant) {
                    fireAndForget(
                        () => applicantDisconnected(this.applicantId, this.loanApplicationId),
                        (e) => logger.warn(`Unable to send applicant disconnected notification! ${e}`)
                    )
                    return
                }

                logger.info(`Attaching network quality handlers for participant ${newTwilioRemoteParticipant.identity}`)
                this.twilioRemoteParticipantNetworkQuality = newTwilioRemoteParticipant.networkQualityLevel
                newTwilioRemoteParticipant.on('networkQualityLevelChanged', (networkQualityLevel) => (this.twilioRemoteParticipantNetworkQuality = networkQualityLevel))

                logger.info(`Attaching participant handlers for participant ${newTwilioRemoteParticipant.identity}`)

                // subscribed === they have made the track available for us
                newTwilioRemoteParticipant.on('trackSubscribed', (track) => {
                    logger.info(`twilioRemoteParticipant published ${track}`)

                    logger.info(`Adding customer track of kind ${track.kind}.`)

                    if (track.kind === 'audio') {
                        this.twilioRemoteAudioTrack = track
                    } else if (track.kind === 'video') {
                        this.twilioRemoteVideoTrack = track
                    } else {
                        logger.error('Unknown twilioRemoteParticipant track added! Unable to mount')
                    }
                })

                // un-subscribed === they have removed the track from the room
                // gets called on remoteParticipant disconnect as well
                newTwilioRemoteParticipant.on('trackUnsubscribed', (track) => {
                    logger.info(`twilioRemoteParticipant unpublished ${track}`)

                    if (track === this.twilioRemoteVideoTrack) {
                        this.twilioRemoteVideoTrack = null
                    } else if (track === this.twilioRemoteAudioTrack) {
                        this.twilioRemoteAudioTrack = null
                    } else {
                        logger.error('Unknown track reference! Unable to remove')
                    }
                })

                fireAndForget(
                    () => applicantConnected(this.applicantId, this.loanApplicationId),
                    (e) => logger.warn(`Unable to send applicant connected notification! ${e}`)
                )
            },
            twilioRemoteAudioTrack: async function (newRemoteAudioTrack, oldRemoteAudioTrack) {
                logger.info(`Updated twilioRemoteVideoTrack from ${oldRemoteAudioTrack} to ${newRemoteAudioTrack}`)

                logger.log(`Unmounting twilioRemoteAudioTrack`)
                this.twilioRemoteAudioTrackElem?.remove()

                if (!newRemoteAudioTrack) {
                    return
                }

                logger.log(`Setting new twilioRemoteAudioTrackElem`)
                this.twilioRemoteAudioTrackElem = attachTrack(newRemoteAudioTrack, this.$refs.twilioRemoteAudioVideo)
            },
            twilioRemoteVideoTrack: async function (newRemoteVideoTrack, oldRemoteVideoTrack) {
                logger.info(`Updated twilioRemoteVideoTrack from ${oldRemoteVideoTrack} to ${newRemoteVideoTrack}`)

                logger.log(`Unmounting twilioRemoteVideoTrack`)
                this.twilioRemoteVideoTrackElem?.remove()

                if (!newRemoteVideoTrack) {
                    return
                }

                // This lets our notary see the wonderful face of our customer
                logger.log(`Setting new twilioRemoteVideoTrackElem, attaching to DOM @ ${this.$refs.twilioRemoteAudioVideo.id}`)
                this.twilioRemoteVideoTrackElem = attachTrack(newRemoteVideoTrack, this.$refs.twilioRemoteAudioVideo)
            },
            isReadyToStartTwilioRoom: async function (newVal, oldVal) {
                logger.info(`Updated isReadyToStartTwilioRoom from ${oldVal} to ${newVal}`)

                // We ignore false values b/c we use this.twilioRoom = null to explicitly tear down a twilio room
                if (!newVal) {
                    return
                }

                logger.log(`Attempting to join room with accessToken ${this.twilioAccessToken}`)
                assert(this.localVideoTrack, "Can't join a twilio twilioRoom with a null localVideoTrack")
                assert(this.localAudioTrack, "Can't join a twilio twilioRoom with a null localAudioTrack")
                assert(this.sessionType === SessionType.RON || this.plotterVideoTrack, "Can't join a twilio twilioRoom with a null plotterVideoTrack in RIN mode")

                await updateNotaryBusyStatus(NotaryBusyStatus.busy)

                const tracksToMount = [this.localVideoTrack, this.localAudioTrack]
                if (this.sessionType === SessionType.RIN) {
                    tracksToMount.push(this.plotterVideoTrack)
                }

                this.twilioRoom = await connect(this.twilioAccessToken, {
                    tracks: tracksToMount,
                    networkQuality: {
                        // Maximum verbosity level for both
                        local: 3,
                        remote: 3,
                    },
                    // bandwidthProfile: {
                    //     video: {
                    //         renderDimensions: {
                    //             high: { width: 320, height: 240 },
                    //             standard: { width: 320, height: 240 },
                    //             low: { width: 320, height: 240 },
                    //         },
                    //     },
                    // },
                    iceTransportPolicy: 'relay',
                    region: 'us2',
                    // https://github.com/twilio/twilio-video.js/issues/453
                    chromeSpecificConstraints: {
                        mandatory: {
                            googCpuOveruseDetection: true,
                            googCpuOveruseEncodeUsage: true,
                            googCpuOveruseThreshold: 50,
                        },
                    },
                })

                logger.info(`Successfully joined room: ${this.twilioRoom.name}`)
            },
        },
        created: async function () {
            if (!appSessionStorage.getItem(localStorageKey.notaryAccessJWT)) {
                logger.info('No creds set, redirecting to login page')
                fireAndForget(
                    () => this.$router.replace(notaryPagePaths.NOTARY_LOGIN),
                    (e) => logger.warn(`Failed to redirect to thanks page for authorization! ${e}`)
                )
            }
        },
        mounted: async function () {
            // Start with fresh applicant state
            this.clearApplicantState()

            // This works b/c the actions panel has an e.preventDefault()
            // which means this will only fire if the user clicks anywhere on the page /except/ the action panel
            window.addEventListener('click', () => (this.uiViewingState.showActions = false))

            // This prevents issues when notaries forget to log-out after their shift and generate error messages
            this.autoLogoutIntervalId = setInterval(() => {
                const msecSinceLastActivity = Date.now() - this.autoLogoutLastActivity
                if (msecSinceLastActivity > this.autoLogoutTimeoutMsec) {
                    logger.log(`${msecSinceLastActivity}msec has passed since last user activity, logging out because greater than timeout of ${this.autoLogoutTimeoutMsec}msec`)
                    fireAndForget(
                        () => this.$router.replace(notaryPagePaths.NOTARY_LOGIN),
                        (e) => logger.warn(`Failed to redirect to notary login page! ${e}`)
                    )
                } else {
                    logger.log(`${msecSinceLastActivity}msec has passed since last user activity, not logging out because less than timeout of ${this.autoLogoutTimeoutMsec}msec`)
                }
            }, 10 * 1000)

            window.addEventListener('mousemove', () => {
                this.autoLogoutLastActivity = Date.now()
            })

            const notaryResponse = await getNotary()
            this.notary = notaryResponse.data.payload.notary

            await this.setAvailableAudioVideoDevices()
            this.selectedVideoDeviceId = this.availableVideoDevices[0].deviceId
            // i.e. prefer the secondary video device if one exists, otherwise use the primary
            this.selectedPlotterVideoDeviceId = (this.availableVideoDevices[1] || this.availableVideoDevices[0]).deviceId
            this.selectedAudioDeviceId = this.availableAudioDevices[0].deviceId

            await this.pollNotaryStatus()

            const inProgressRoomCheckPayload = await checkForInProgressRoom()
            if (inProgressRoomCheckPayload.isRoomInProgress) {
                await this.joinInProgressRoom(inProgressRoomCheckPayload)
            } else {
                await this.goIdle()
            }
        },
        errorCaptured(error, component, info) {
            logger.warn(`Received error: ${inspect(error)} / from dependent component: ${inspect(component)} / info: ${inspect(info)}`)
            this.errorText = NotaryApiErrorHandler(error)
            // Prevent errors from propagating further up the call stack
            return false
        },
        beforeDestroy() {
            logger.log('Before destroy triggered, running unmounted()')
            this.unmounted()
        },
        methods: {
            pollTimeRemaining: async function () {
                if (this.pollTimeRemainingIntervalId) {
                    logger.log('Refusing to pollTimeRemaining when pollTimeRemainingIntervalId exists')
                    return
                }

                logger.info('Beginning to poll for time remaining')
                this.pollApplicantStatusIntervalId = window.setInterval(() => {
                    if (this.sessionStartTime && this.sessionScheduledDurationMin) {
                        const expectedSessionEndTime = DateTime.fromJSDate(this.sessionStartTime).plus({ minute: this.sessionScheduledDurationMin / this.sessionScheduledDurationAdjustmentFactor })
                        this.timeRemainingDuration = expectedSessionEndTime.diffNow()
                    }
                }, 1000)
            },
            unmounted: function () {
                this.clearApplicantState()

                logger.log('Unloading page and disconnecting AV tracks')
                this.twilioRoom = null

                // Clear out our polling calls
                window.clearInterval(this.pollNotaryStatusIntervalId)
                window.clearInterval(this.networkStatsLogIntervalId)
                window.clearInterval(this.pollForInSessionApplicantIntervalId)
                window.clearInterval(this.autoLogoutIntervalId)

                logger.log('Attempting to stop all media streams and tracks')
                this.localAudioTrack = null
                this.localVideoTrack = null
                this.plotterVideoTrack = null
            },
            clickButtonShowLastTransferDocument: function () {
                this.$logEvent('click_button_show_last_transfer_doc')
                this.primaryView = PrimaryView.lastTransferDocument
            },
            clickButtonShowDocuSign: function () {
                this.$logEvent('click_button_show_docusign')
                this.primaryView = PrimaryView.docuSign
            },
            clickButtonShowDeedOfTrust() {
                this.$logEvent('click_button_show_deed_of_trust')
                this.primaryView = PrimaryView.deedOfTrust
            },
            clickButtonShowNotarialCertificate() {
                this.$logEvent('click_button_show_notarial_certificate')
                this.primaryView = PrimaryView.notarialCertificate
            },
            onScannedUploadSuccess: async function () {
                logger.info('Scanned file upload successful')
            },
            clickShowIdVerificationReport() {
                this.$logEvent('click_button_toggle_id_verification')
                this.uiViewingState.isShowingIdVerificationReport = !this.uiViewingState.isShowingIdVerificationReport
                if (this.uiViewingState.isShowingIdVerificationReport) {
                    this.uiViewingState.isShowingScannedFileUpload = false
                }
            },
            clickButtonRegenerateDocusign: async function () {
                this.$logEvent('click_button_regenerate_docusign')
                this.uiViewingState.showActions = false
                await regenerateDocusign(this.applicantNotaryAssignmentId)
                await setBackendSessionState(SessionState.refreshDocuSign, this.applicantId, this.loanApplicationId)
                this.primaryView = PrimaryView.docuSign
            },
            clickButtonShowScannedFileUpload() {
                this.$logEvent('click_button_toggle_upload_scanned_file')
                this.uiViewingState.isShowingScannedFileUpload = !this.uiViewingState.isShowingScannedFileUpload
                if (this.uiViewingState.isShowingScannedFileUpload) {
                    this.uiViewingState.isShowingIdVerificationReport = false
                }
            },
            clickButtonApplicantViewingVideoFeed() {
                this.$logEvent('click_button_applicant_viewing_video_feed')
                this.setNewSessionState(SessionState.viewingFaceVideos)
            },
            clickButtonShowIdVerificationMode() {
                this.$logEvent('click_button_applicant_id_verification')
                this.uiViewingState.isShowingIdVerificationReport = true
                this.setNewSessionState(SessionState.performingFrontIdVerification)
            },
            clickButtonPlotterSigning() {
                this.$logEvent('click_button_plotter_signing')
                this.setNewSessionState(SessionState.gatherPlotterSignature)
            },
            clickButtonApplicantViewingDocusign() {
                this.$logEvent('click_button_applicant_viewing_docusign')
                this.setNewSessionState(SessionState.viewingDocuSign)
            },
            clickButtonApplicantViewingDeedOfTrust() {
                this.$logEvent('click_button_applicant_viewing_deed_of_trust')
                this.uiViewingState.hasShownDeedOfTrust = true
                this.setNewSessionState(SessionState.viewingDeedOfTrust)
            },
            goIdle: async function () {
                this.uiViewingState.isShowingScannedFileUpload = false
                this.uiViewingState.isShowingIdVerificationReport = false
                this.checkboxOptions = {}
                await updateNotaryBusyStatus(NotaryBusyStatus.idle)
                await this.fetchInSessionApplicant()
            },
            fetchInSessionApplicant: async function () {
                try {
                    const inSessionApplicantsResponse = await getInSessionApplicant()
                    assert(inSessionApplicantsResponse?.data?.success, 'inSessionApplicantResponse.data.success is not true')

                    if (isEqual(this.userInfo, inSessionApplicantsResponse.data.payload)) {
                        // Discard results, they are the same as what we already have
                        // Re-assigning this.userInfo triggers a lot of computed properties
                        // Which is a waste of CPU if the content has not changed
                        return
                    }

                    this.userInfo = inSessionApplicantsResponse.data.payload
                    logger.info(`userInfo: ${JSON.stringify(this.userInfo)}`)

                    if (this.userInfo?.homeResidenceType === ResidenceType.SECONDARY) {
                        systemFraudFlagsHelper.addFlag({ reasonEnums: [AutoFraudFlagReasons.NOOSecondaryResidenceType], reasons: `NOO: home residence type is ${this.userInfo?.homeResidenceType}` })
                    }
                } catch (error) {
                    this.errorText = NotaryApiErrorHandler(error)
                }
            },
            joinInProgressRoom: async function (payload) {
                assert(payload.applicantId, 'applicant id must exist')
                assert(payload.loanApplicationId, 'loan application id must exist')

                this.applicantNotaryAssignmentId = payload.applicantNotaryAssignmentId

                assert(payload.notaryAccessToken, 'notary access token must exist')
                await this.fetchInSessionApplicant()

                this.twilioAccessToken = payload.notaryAccessToken
            },
            clearApplicantState: function () {
                logger.info('Clearing applicant state...')
                appSessionStorage.removeItem(localStorageKey.alreadySubmittedFraudFlag)
                appSessionStorage.removeItem(localStorageKey.systemFraudFlags)
            },
            nextCustomer: async function () {
                this.$logEvent('click_button_next_customer')
                this.clearApplicantState()

                await this.fetchInSessionApplicant() // update one last time

                const beginRemoteNotarizationSessionResponse = await beginRemoteNotarizationSession(this.applicantId, this.loanApplicationId)
                if (beginRemoteNotarizationSessionResponse.data.success) {
                    const payload = beginRemoteNotarizationSessionResponse.data.payload
                    logger.info(inspect(payload))

                    this.applicantNotaryAssignmentId = payload.applicantNotaryAssignmentId

                    assert(payload.notaryAccessToken, 'notary access token must exist')
                    this.twilioAccessToken = payload.notaryAccessToken
                    await this.setNewSessionState(payload.sessionState)
                } else {
                    logger.error(`Notary could not start next customer! This might mean that the session they were trying to start was already successfully complete`)
                }
            },
            takeIDPicture: async function () {
                logger.info('Triggering taking of license picture')
                this.$logEvent('click_button_take_id_picture')

                this.uiViewingState.isShowingIdVerificationReport = true
                this.uiViewingState.isShowingScannedFileUpload = false

                fireAndForget(
                    () => new Audio(require('@/assets/sounds/camera-shutter-click.mp3')).play().catch(),
                    (error) => logger.info(` Somehow received an error playing the camera shutter sound (probably Safari): ${error}`)
                )

                if (this.sessionState === SessionState.performingFrontIdVerification) {
                    // trigger customer side to play sound, take picture, and send to backend
                    await setBackendSessionState(SessionState.takeFrontIDPicture, this.applicantId, this.loanApplicationId)
                } else if (this.sessionState === SessionState.performingBackIdVerification) {
                    // trigger customer side to play sound, take picture, and send to backend
                    await setBackendSessionState(SessionState.takeBackIDPicture, this.applicantId, this.loanApplicationId)
                    this.uiViewingState.showActions = false

                    await this.$refs.personaInSessionIdVerification.pollForIdVerificationResults()
                } else {
                    this.errorText = `Taking a photo from an unexpected state: ${this.sessionState}. Try again?`
                }
            },
            setAvailableAudioVideoDevices: async function () {
                logger.log('Fetching media devices available on notary machine...')
                // Collects all audio and video mediaDevices
                await navigator.mediaDevices.getUserMedia({ audio: true, video: true })
                const mediaDevices = await navigator.mediaDevices.enumerateDevices()
                logger.log(`Got ${mediaDevices?.length} media devices: ${JSON.stringify(mediaDevices)}`)

                this.availableVideoDevices = mediaDevices
                    .filter((device) => device.kind === 'videoinput')
                    // Blacklist krisp video devices (expand as necessary)
                    .filter((device) => !/krisp/gi.test(device.label))
                logger.log(`Got ${this.availableVideoDevices.length} video devices: ${JSON.stringify(this.availableVideoDevices)}`)

                this.availableAudioDevices = mediaDevices.filter((device) => device.kind === 'audioinput')
                logger.log(`Got ${this.availableAudioDevices.length} audio devices: ${JSON.stringify(this.availableAudioDevices)}`)
            },
            openFlagFraudModal() {
                this.uiViewingState.isFraudModalOpen = true
                this.$logEvent('click_button_open_fraud_modal')
            },
            closeFlagFraudModal() {
                this.uiViewingState.isFraudModalOpen = false
                this.$logEvent('click_button_close_fraud_modal')
            },
            flagFraudAccount: async function (fraudFlag) {
                try {
                    logger.log(`Flagging current loan application ${this.loanApplicationId} as fraudulent`)
                    const loanApplicationId = this.loanApplicationId
                    await postUpdateFraudFlag(loanApplicationId, fraudFlag)
                } catch (e) {
                    this.errorText = `An error has occurred while flagging the loan application: ${e?.message || ''}`
                    logger.error(`An error has occurred while flagging the loan application ${this.loanApplicationId} as fraudulent`, null /* event */, e)
                }
            },
            showFailureReasonModal: function (sessionState, sessionType) {
                this.uiViewingState.failureReasonModalState = {
                    open: true,
                    sessionState,
                    sessionType,
                }
                this.$logEvent('click_button_show_notary_failure_reason_modal')
            },
            closeFailureReasonModal: function () {
                this.uiViewingState.failureReasonModalState = {
                    open: false,
                    sessionState: null,
                    sessionType: null,
                }
                this.$logEvent('click_button_close_notary_failure_reason_modal')
            },
            submitFailureReason: function (event) {
                logger.info(`Submitting failure reason of ${event.failureReason} for ana ${this.applicantNotaryAssignmentId} in state ${event.sessionState}`)
                this.endSession(event.sessionState, event.failureReason, event.failureReasonDescription, event.sendToL2Notary)
                this.uiViewingState.failureReasonModalState = {
                    open: false,
                    sessionState: null,
                    sessionType: null,
                }
            },
            closeReferralSourceModal() {
                logger.info(`Closing referral source modal`)
                this.uiViewingState.isReferralSourceModalOpen = false
                this.$logEvent('event_close_referral_source_modal')
            },
            submitReferralSource: async function (referralSource) {
                this.$logEvent('click_button_submit_referral_source')
                const otherDescriptionMsg = referralSource.otherDescription ? ` (otherDescription: ${referralSource.otherDescription})` : ''
                try {
                    logger.info(`Setting referral source of ${referralSource.source} for loan application ${this.loanApplicationId}${otherDescriptionMsg}`)
                    await setReferralSource(this.loanApplicationId, referralSource.source, referralSource.otherDescription)
                } catch (e) {
                    logger.error(
                        `Error setting referral source of ${referralSource.source} for loan application ${this.loanApplicationId}${otherDescriptionMsg}. Still moving on to end session with success`,
                        e
                    )
                } finally {
                    this.uiViewingState.isReferralSourceModalOpen = false
                    await this.endSession(SessionState.sessionCompleteSuccess)
                }
            },
            ejectApplicantFromSession: async function () {
                this.$logEvent('click_button_eject_applicant')

                logger.log('Ejecting applicant from the session')
                await setBackendSessionState(SessionState.ejectApplicantFromCall, this.applicantId, this.loanApplicationId)
            },
            endSessionWithSuccess: async function () {
                // Allow ending the call with failure but not success if DocuSign has not been completed
                if (!this.$refs.docuSign?.haveBothPartiesSigned()) {
                    logger.log(`Confirming notary really wants to end session with missing docusign signatures for ANA: ${this.applicantNotaryAssignmentId}`)
                    if (!confirm('DocuSign appears to be missing applicant signature OR notary signature. ARE YOU SURE you want to complete the session?')) {
                        logger.log(`Notary changed their mind about forcing a session completion with empty docusign for ANA: ${this.applicantNotaryAssignmentId}, canceled`)
                        return
                    }
                    logger.log(`Notary decided they really do want to end the session successfully with an incomplete docusign for ANA: ${this.applicantNotaryAssignmentId}`)
                }
                if (this.isPrimaryApplicant) {
                    logger.info(`Ending primary applicant with success; asking for referral source first`)
                    this.uiViewingState.isReferralSourceModalOpen = true
                    this.$logEvent('event_open_referral_source_modal')
                } else {
                    logger.info(`Ending secondary applicant with success`)
                    await this.endSession(SessionState.sessionCompleteSuccess)
                }
            },
            endSession: async function (sessionState, failureReason, failureReasonDescription, sendToL2Notary) {
                if (sessionState === SessionState.sessionPartialFailure) {
                    logger.log(`Confirming notary really wants to re-notarize everyone (current ANA: ${this.applicantNotaryAssignmentId})`)
                    if (!confirm('Are you sure you want to re-notarize everyone?')) {
                        logger.log(`Notary changed their mind about re-notarizing everyone, canceled (current ANA: ${this.applicantNotaryAssignmentId})`)
                        return
                    }
                    logger.log(`Notary decided they really to want to re-notarize everyone (current ANA: ${this.applicantNotaryAssignmentId})`)
                }

                // Auto flag application if we have system notes but the application has not been flagged
                try {
                    const alreadySubmittedFraudFlag = appSessionStorage.getItem(localStorageKey.alreadySubmittedFraudFlag)
                    const systemFraudFlags = systemFraudFlagsHelper.getFlags()
                    if (!alreadySubmittedFraudFlag && systemFraudFlags.length > 0) {
                        logger.info(`Found system fraud notes, auto flagging application L${this.loanApplicationId}`)
                        await postUpdateFraudFlag(this.loanApplicationId)
                    }
                } catch (e) {
                    logger.error(
                        `failed to post system fraud flag ${appSessionStorage.getItem(localStorageKey.systemFraudFlags)} for L: ${this.loanApplicationId} | A: ${this.applicantId} | ANA: ${
                            this.applicantNotaryAssignmentId
                        }`,
                        null /* event */,
                        e
                    )
                }

                // If the notary is ending the session with success, we'll confirm with DocuSign to make sure
                // all signers have, in fact, signed. If not, we'll notify the notary and ask them to ensure
                // all signatures are complete before finishing the session.
                // Todo Probably better to do this check on the backend as part of the API that ends the session.
                if (sessionState === SessionState.sessionCompleteSuccess) {
                    try {
                        const signingStatusResponse = await getDocuSignEnvelopeSigningStatus(this.applicantNotaryAssignmentId)
                        if (!signingStatusResponse.data.payload.hasApplicantSigned || !signingStatusResponse.data.payload.hasNotarySigned) {
                            const msg = `Not all parties have fully signed and/or notarized! These are the parties that still need to sign -> ${
                                signingStatusResponse.data.payload.hasApplicantSigned ? '' : 'applicant needs to sign,'
                            } ${signingStatusResponse.data.payload.hasNotarySigned ? '' : 'notary needs to sign'}`
                            logger.log(msg)
                            alert(msg)
                            return
                        }
                    } catch (e) {
                        // If the API call fails, we'll let the notary continue as usual, making the assumption that
                        // all the frontend checks caught any abnormalities in the docs (e.g. not all signers signed)
                        logger.warn(`Couldn't get a DocuSign envelope signing status`, e)
                    }
                }

                try {
                    if (sessionState === SessionState.sessionCompleteSuccess) {
                        this.$logEvent('click_button_end_call_with_success')
                    } else if (sessionState === SessionState.sessionPartialFailure) {
                        this.$logEvent('click_button_end_call_with_partial_failure')
                    } else {
                        this.$logEvent('click_button_end_call_with_failure')
                    }

                    this.$refs.personaInSessionIdVerification.reset()
                    this.$refs.personaPreSessionIdVerification.reset()

                    logger.info(`Ending room for A: ${this.applicantId}, L: ${this.loanApplicationId}, ANA: ${this.applicantNotaryAssignmentId}`)

                    this.twilioRoom = null

                    await endRemoteNotarizationSession(this.applicantId, this.loanApplicationId, sessionState, failureReasonDescription, failureReason, sendToL2Notary)
                } catch (e) {
                    logger.error(`failed to end ana ${this.applicantNotaryAssignmentId} due to error`, null /* event */, e)
                } finally {
                    // Clear old applicant state, hopefully backend has updated internal applicant state by now
                    this.userInfo = null
                    this.applicantNotaryAssignmentId = null
                }

                await this.goIdle()
            },
            logTwilioNetworkStats: async function () {
                logger.info(this.prettyPrintNetworkQualityStats(this.twilioRoom.localParticipant))
                this.twilioRoom.participants.forEach((participant) => logger.info(this.prettyPrintNetworkQualityStats(participant)))
            },
            prettyPrintNetworkQualityStats(participant) {
                if (participant.networkQualityStats) {
                    let loggedString = `Network Quality Stats for ${participant.identity}\n`
                    loggedString += `\tAudio Received:\n ${this.prettyPrintNetworkQualityStatsSpecific(participant.networkQualityStats.audio.recvStats)}\n`
                    loggedString += `\tAudio Sent:\n ${this.prettyPrintNetworkQualityStatsSpecific(participant.networkQualityStats.audio.sendStats)}\n`
                    loggedString += `\tVideo Received:\n ${this.prettyPrintNetworkQualityStatsSpecific(participant.networkQualityStats.video.recvStats)}\n`
                    loggedString += `\tVideo Sent:\n ${this.prettyPrintNetworkQualityStatsSpecific(participant.networkQualityStats.video.sendStats)}\n`
                    return loggedString
                }
                return 'Network Quality Stats Not Found'
            },
            prettyPrintNetworkQualityStatsSpecific(obj) {
                let loggedString = `\t\tBandwidth - ${obj.bandwidth.actual} / ${obj.bandwidth.available} bytes available (level: ${obj.bandwidth.level})\n`
                loggedString += `\t\tLatency - jitter: ${obj.latency.jitter} seconds / rtt: ${obj.latency.rtt} seconds (level: ${obj.latency.level})\n`
                loggedString += `\t\tPacket Loss - ${obj.fractionLost.fractionLost} packets lost (level: ${obj.fractionLost.level})`
                return loggedString
            },
            pollNotaryStatus: async function () {
                logger.info('Beginning to poll for notary status')
                await this.tryGetNotaryStatus()
                this.pollNotaryStatusIntervalId = window.setInterval(async () => {
                    await this.tryGetNotaryStatus()
                }, this.pollNotaryStatusIntervalInMs)
            },
            tryGetNotaryStatus: async function () {
                try {
                    this.notaryStatus = await getNotaryStatus(this.applicantId, this.loanApplicationId)
                } catch (error) {
                    this.errorText = NotaryApiErrorHandler(error)
                }
            },
            goOffline: async function () {
                await updateNotaryBusyStatus(NotaryBusyStatus.offline)
                await this.$router.replace(notaryPagePaths.NOTARY_LOGIN)
            },
            setNewSessionState: async function (nextState) {
                // update based on specific state
                logger.info(`Changing session state from ${this.sessionState} to: ${nextState}`)

                await setBackendSessionState(nextState, this.applicantId, this.loanApplicationId)
                logger.info(`Successfully updated session state to ${nextState} via the backend`)
            },
            toggleUpdateApplicantFieldsModal: function () {
                logger.info(`Toggling update applicant fields modal`)

                if (this.showUpdateApplicantFieldsModal) {
                    logger.info('Hiding update applicant fields modal')
                    this.showUpdateApplicantFieldsModal = false
                    return
                }

                logger.info('Showing update applicant fields modal')
                this.showUpdateApplicantFieldsModal = true
            },
        },
    }
</script>

<style lang="scss">
    @import '../../styles/pages/notary/notarySession';
</style>
