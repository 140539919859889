<template>
    <modal
        title="Update applicant data"
        :show="show"
        @close="hideUpdateApplicantFieldsModal"
    >
        <form-container
            id="updateApplicantFieldsForm"
            ref="updateApplicantFieldsForm"
            @on-submit="onSubmitApplicantInfoChanges"
            @onError="errorUpdatingApplicantFieldsText = UPDATE_APPLICANT_FIELDS_ERROR_MESSAGE"
        >
            <div
                v-if="errorUpdatingApplicantFieldsText"
                class="alert alert-warning"
                role="alert"
            >
                {{ errorUpdatingApplicantFieldsText }}
            </div>
            <span class="text-muted me-1">Selected Applicant: </span>
            <select
                v-model="selectedApplicantId"
                class="mb-2 form-control fw-normal w-auto d-inline-block pb-1"
            >
                <option
                    v-for="applicant in applicantsInfo"
                    :value="applicant.id"
                    :key="applicant.id"
                >
                    {{ applicant.firstName }} {{ applicant.lastName }} ({{ applicant.type }})
                </option>
            </select>
            <div v-if="selectedApplicantIdNullDuringChanges">
                <div class="form-row">
                    <div class="mb-2 col">
                        <form-field
                            v-model="updatedFirstName"
                            name="updatedFirstName"
                            :placeholder="selectedApplicantFirstNamePlaceholder"
                            validation="min:2"
                            autocomplete="given-name"
                        />
                    </div>
                    <div class="mb-2 col">
                        <form-field
                            v-model="updatedLastName"
                            name="updatedLastName"
                            :placeholder="selectedApplicantLastNamePlaceholder"
                            validation="min:2"
                            autocomplete="family-name"
                        />
                    </div>
                </div>

                <div class="form-row">
                    <div class="mb-2 col">
                        <form-field-date
                            v-model="updatedDateOfBirth"
                            name="dob"
                            validation="dateRule|ageRule"
                            :placeholder="selectedApplicantDOBPlaceholder"
                            :focus-placeholder="selectedApplicantDOBPlaceholder"
                        />
                    </div>
                </div>

                <div class="form-row">
                    <div class="mb-2 col">
                        <form-text-area
                            v-model="reason"
                            name="reason"
                            validation="required|min:15"
                            validation-mode="eager"
                            :placeholder="$t('components.applicationForm.placeholder.applicantUpdateReason')"
                            :focus-placeholder="$t('components.applicationForm.placeholder.applicantUpdateReason')"
                        />
                    </div>
                </div>
                <div class="d-grid">
                    <form-button
                        class="mb-2"
                        label="Submit"
                        :submitting="submitting"
                        type="submit"
                        event-name="click_button_submit_personal_info_form"
                    />
                </div>
            </div>
        </form-container>
    </modal>
</template>

<script>
    import Modal from '@/components/Modal'
    import FormTextArea from '@/components/base/FormTextArea'
    import FormContainer from '@/components/base/FormContainer'
    import FormField from '@/components/base/FormField'
    import FormFieldDate from '@/components/base/FormFieldDate'
    import FormButton from '@/components/base/FormButton'
    import { logger } from '@/utils/logger'
    import { updateApplicantDetails } from '@/services/api'
    import format from '@/mixins/format'
    import { convertDateTimeFormat } from '@/utils/date'

    const UPDATE_APPLICANT_FIELDS_ERROR_MESSAGE = 'Error updating fields. Please reach out to an engineer on Slack!'

    export default {
        name: 'UpdateApplicantFieldsModal',
        mixins: [format],
        components: {
            modal: Modal,
            'form-text-area': FormTextArea,
            'form-container': FormContainer,
            'form-field': FormField,
            'form-field-date': FormFieldDate,
            'form-button': FormButton,
        },
        props: {
            show: {
                type: Boolean,
                default: false,
            },
            // see remoteNotarizationController.ts:542 for proper type
            // type updatableApplicantInfo = NonNullable<Pick<Applicant, "firstName" | "lastName" | "dateOfBirth" | "type" | "id">>
            applicantsInfo: {
                type: Array,
                required: true,
            },
            /* The ID of the applicantNotaryAssignment record for the PRIMARY applicant. We use
             * the primary's ANA even when updating another applicant's values because the notary
             * can only update during the primary's session.
             */
            primaryApplicantNotaryAssignmentId: {
                type: Number,
                required: true,
            },
        },
        data() {
            return {
                UPDATE_APPLICANT_FIELDS_ERROR_MESSAGE,
                errorUpdatingApplicantFieldsText: null,
                submitting: false,
                updatedFirstName: null,
                updatedLastName: null,
                updatedDateOfBirth: null,
                reason: null,
                selectedApplicantId: null,
                // This is a temporary workaround to re-create all of the form fields
                // every time the current applicant selection is changed
                // otherwise the placeholders won't update
                selectedApplicantIdNullDuringChanges: null,
            }
        },
        computed: {
            selectedApplicantFirstNamePlaceholder() {
                return this.selectedApplicant?.firstName || 'First Name'
            },
            selectedApplicantLastNamePlaceholder() {
                return this.selectedApplicant?.lastName || 'Last Name'
            },
            selectedApplicantDOBPlaceholder() {
                return this.selectedApplicant?.dateOfBirth ? convertDateTimeFormat(this.selectedApplicant.dateOfBirth, 'UTC', 'UTC', 'MM/DD/YYYY') : 'Date of Birth (mm/dd/yyyy)'
            },
            selectedApplicant() {
                return this.applicantsInfo?.filter((x) => x.id === this.selectedApplicantId)?.[0]
            },
        },
        watch: {
            selectedApplicantId(newValue) {
                this.selectedApplicantIdNullDuringChanges = null
                // $nextTick is necessary to wait for the UI to update to use the new null value
                this.$nextTick(() => {
                    this.selectedApplicantIdNullDuringChanges = newValue
                })
            },
        },
        methods: {
            hideUpdateApplicantFieldsModal: async function () {
                logger.info('Requesting to hide update applicant fields modal')
                this.errorUpdatingApplicantFieldsText = null
                await this.clearUpdateApplicantFieldsForm()
                this.$emit('request-hide-modal')
            },
            onSubmitApplicantInfoChanges: async function () {
                this.submitting = true
                try {
                    const firstNameLog = `First name -> ${this.updatedFirstName ? this.updatedFirstName : 'no change'}`
                    const lastNameLog = `Last name -> ${this.updatedLastName ? this.updatedLastName : 'no change'}`
                    const dobLog = `DOB -> ${this.updatedDateOfBirth ? this.updatedDateOfBirth : 'no change'}`
                    const reasonLog = `Reason -> ${this.reason}`

                    let isValid = await this.$refs.updateApplicantFieldsForm.$refs.observer.validate()
                    isValid = isValid && (this.updatedFirstName || this.updatedLastName || this.updatedDateOfBirth)
                    if (!isValid) {
                        this.errorUpdatingApplicantFieldsText = 'Make sure all fields are valid before submitting!'
                        logger.info(`Notary did not submit valid applicant updates: ${firstNameLog}, ${lastNameLog}, ${dobLog}, ${reasonLog}`)
                        return
                    }
                    logger.info(`Date type = ${typeof this.updatedDateOfBirth}`)
                    logger.info(`Notary trying to update applicant info: ${firstNameLog}, ${lastNameLog}, ${dobLog}, ${reasonLog}`)
                    await updateApplicantDetails(
                        this.selectedApplicantId,
                        this.primaryApplicantNotaryAssignmentId,
                        this.updatedFirstName,
                        this.updatedLastName,
                        this.updatedDateOfBirth,
                        true,
                        this.reason
                    )
                    logger.info('Re-fetching applicant info')
                    this.$emit('successful-update')
                    await this.hideUpdateApplicantFieldsModal()
                } catch (e) {
                    this.errorUpdatingApplicantFieldsText = UPDATE_APPLICANT_FIELDS_ERROR_MESSAGE
                    logger.error(`Notary couldn't update applicant fields due to error`, null /* event */, e)
                } finally {
                    this.submitting = false
                }
            },
            clearUpdateApplicantFieldsForm: function () {
                this.updatedFirstName = null
                this.updatedLastName = null
                this.updatedDateOfBirth = null
                this.reason = null
            },
        },
    }
</script>
