<template>
    <div class="persona-id-verification-container">
        <span> In-session Result: </span>
        <div
            class="persona-id-verification-error-container alert alert-warning"
            v-show="isError"
            role="alert"
        >
            <span v-html="errorTextWithAdditionalInfoForNotary" />
        </div>
        <div
            v-show="isLoading"
            class="section-header text-muted"
        >
            Waiting for results...
        </div>
        <div v-show="showReport">
            <p class="mb-0">
                <span class="text-muted">Verification Status: </span>
                <span
                    class="fw-bold"
                    data-testid="persona-id-verification-status"
                >
                    {{ toProperCase(status) }}
                </span>
            </p>
            <p class="mb-0">
                <span class="text-muted">Document Type: </span>
                <span
                    class="fw-bold"
                    data-testid="persona-id-type"
                >
                    {{ toProperCase(documentType) }}
                </span>
            </p>
            <p class="mb-0">
                <span class="text-muted">Front Photo: </span>
                <img
                    :src="frontIDPhotoUrl"
                    alt="Front of ID"
                    data-testid="persona-id-front-picture"
                >
            </p>
            <p class="mb-0">
                <span class="text-muted">Back Photo: </span>
                <img
                    :src="backIDPhotoUrl"
                    alt="Back of ID"
                    data-testid="persona-id-back-picture"
                >
            </p>
        </div>
        <div v-show="showEmpty">
            <p>No ID image has yet been taken. One will show below after a picture has been captured.</p>
        </div>
    </div>
</template>
<script>
    import { toProperCase } from '@/utils/remoteNotarization'
    import { getInSessionIdVerificationInfo, PersonaVerificationStatus } from '@/services/remoteNotarizationApi'
    import { logger } from '@/utils/logger'
    import { systemFraudFlagsHelper, AutoFraudFlagReasons } from '@/utils/systemFraudFlagsHelper'

    const ViewingState = {
        error: 'error',
        loading: 'loading',
        showingReport: 'showingReport',
        empty: 'empty',
    }

    export default {
        name: 'PersonaInSessionIdVerification',
        props: {
            applicantId: { type: Number },
            loanApplicationId: { type: Number },
        },
        data() {
            return {
                toProperCase,
                pollIntervalInMs: 3000, // recommended by persona
                maxAttempts: 20, // total of pollIntervalInMs * maxRetrievalAttempts / 1000 seconds waiting for an Id before giving up completely and showing an error (notary can take another picture at any time to reset this process)
                numAttempts: 0,
                pollIntervalId: null,

                errorText: null,
                loading: false,

                // Keep track of the ID of the most recent completed verification this
                // component displayed. Used to make sure it's displaying the right
                // information if the backend is working on a new verification.
                lastCompletedVerificationId: null,
                lastCompletedInquiryId: null,

                // extracted components of verification report
                status: null,
                needsReviewChecks: [],
                documentType: null,

                // These will generally be blob:// urls
                frontIDPhotoUrl: null,
                backIDPhotoUrl: null,
            }
        },
        mounted: function () {
            this.main()
        },
        computed: {
            isMissingCredentials() {
                return !this.loanApplicationId || !this.applicantId
            },
            isReportPresent() {
                return this.frontIDPhotoUrl && this.backIDPhotoUrl && this.documentType
            },
            // forces mutual exclusivity of states
            viewingState() {
                if (this.errorText) return ViewingState.error
                if (this.loading) return ViewingState.loading
                if (this.isReportPresent) return ViewingState.showingReport
                return ViewingState.empty
            },
            showReport() {
                return this.viewingState === ViewingState.showingReport
            },
            isError() {
                return this.viewingState === ViewingState.error
            },
            isLoading() {
                return this.viewingState === ViewingState.loading
            },
            isPassed() {
                return this.state === PersonaVerificationStatus.passed
            },
            showEmpty() {
                return this.viewingState === ViewingState.empty
            },
            errorTextWithAdditionalInfoForNotary() {
                return (
                    'Something went wrong with this component. ' +
                    'You may be able to fix this by taking a new picture via the dropdown menu when in ID Verification mode. ' +
                    'Error for reference: ' +
                    this.errorText
                )
            },
        },
        methods: {
            main: async function () {
                // retry if notary does not yet have applicant id and loan application id
                if (this.isMissingCredentials) {
                    setTimeout(this.main, this.pollIntervalInMs)
                    return
                }

                // upon gaining credentials, perform an initial check for an existing ID verification report
                this.loading = true
                await this.tryGetIdVerificationReport()
                this.loading = false
            },
            tryGetIdVerificationReport: async function () {
                logger.info(`Trying to get in-session ID Verification Report for A: ${this.applicantId} | L: ${this.loanApplicationId}`)
                // Increment num attempts and determine whether we continue or stop
                this.numAttempts += 1
                if (this.numAttempts >= this.maxAttempts) {
                    this.stopPolling()
                    this.errorText = 'Maximum number of attempts exceeded (Internally generated Aven error).'
                    logger.log(`Error doing persona in-session id verification for A: ${this.applicantId} | L: ${this.loanApplicationId} with error: ${this.errorText}`)
                    return
                }

                try {
                    const response = await getInSessionIdVerificationInfo(this.applicantId, this.loanApplicationId)
                    this.errorText = null

                    if (!response.data.success) {
                        logger.info(
                            `in-session ID Verification Report not yet complete for A: ${this.applicantId} | L: ${this.loanApplicationId}, trying again in ${this.pollIntervalInMs} ms (attempt #${this.numAttempts}/${this.maxAttempts})`
                        )
                        return
                    }

                    const payload = response.data.payload
                    logger.info(`in-session ID Verification Report complete ${payload.verificationId} for A: ${this.applicantId} | L: ${this.loanApplicationId}`)
                    await this.parseIdVerificationReport(payload)
                } catch (error) {
                    logger.log(`Error pulling in-session ID verification info for A: ${this.applicantId} | L: ${this.loanApplicationId}: ${error}`)
                    this.errorText = error.message
                }
            },
            parseIdVerificationReport: async function (verificationReportPayload) {
                if (this.lastCompletedVerificationId === verificationReportPayload.verificationId) {
                    logger.info(
                        `Parsed same Persona verification report ${this.lastCompletedVerificationId} as before for A: ${this.applicantId} | L: ${this.loanApplicationId}. Continuing to poll for new report.`
                    )
                    return
                }
                logger.info(`Parsing Persona verification report for A: ${this.applicantId} | L: ${this.loanApplicationId}: ${JSON.stringify(verificationReportPayload)}`)

                // Stop polling and store all collected information
                this.stopPolling()

                this.lastCompletedVerificationId = verificationReportPayload.verificationId
                this.lastCompletedInquiryId = verificationReportPayload.inquiryId
                this.status = verificationReportPayload.status
                this.needsReviewChecks = verificationReportPayload.needsReviewChecks
                this.documentType = verificationReportPayload.documentType

                if (this.status === PersonaVerificationStatus.needsReview) {
                    systemFraudFlagsHelper.addFlag({
                        reasonEnums: [AutoFraudFlagReasons.inSessionPersonaVerificationNeedsReview],
                        reasons: `In-session Persona ID verification (inquiryId: ${this.lastCompletedInquiryId}): Needs Review for failing checks ${this.needsReviewChecks.join(',')}`,
                    })
                }

                // We do this to avoid storing the full b64 image in component memory
                // This would cause a very large state dump during a sentry exception
                // By storing the data in a blob url, we can offload the data storage to the browser
                console.log('Converting front photo image to blob url')
                const frontIDPhotoB64 = verificationReportPayload.frontPhotoData
                const frontIDPhotoBlob = await (await fetch(frontIDPhotoB64)).blob()
                this.frontIDPhotoUrl = URL.createObjectURL(frontIDPhotoBlob)

                console.log('Converting back photo image to blob url')
                const backIDPhotoB64 = verificationReportPayload.backPhotoData
                const backIDPhotoBlob = await (await fetch(backIDPhotoB64)).blob()
                this.backIDPhotoUrl = URL.createObjectURL(backIDPhotoBlob)

                console.log('Front + Back photo data conversion complete!')
            },
            reset: function () {
                logger.info(`Resetting in-session ID verification for A: ${this.applicantId} | L: ${this.loanApplicationId}`)
                this.stopPolling()

                this.errorText = null

                this.status = null
                this.frontIDPhotoUrl = null
                this.backIDPhotoUrl = null
                this.documentType = null
            },
            pollForIdVerificationResults: async function () {
                logger.info(`Beginning to poll for in-session ID verification results for A: ${this.applicantId} | L: ${this.loanApplicationId}`)
                this.reset()
                this.loading = true
                this.pollIntervalId = setInterval(this.tryGetIdVerificationReport, this.pollIntervalInMs)
            },
            stopPolling: function () {
                logger.info(`Stopping polling for in-session ID verification results for A: ${this.applicantId} | L: ${this.loanApplicationId}`)
                window.clearInterval(this.pollIntervalId)
                this.pollIntervalId = null
                this.numAttempts = 0
                this.loading = false
            },
        },
    }
</script>

<style lang="scss">
    img {
        width: 100%;
    }

    .loading-indicator {
        min-height: 0 !important;
    }
</style>
