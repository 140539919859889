<template>
    <div
        class="modal fade"
        tabindex="-1"
        role="dialog"
        :class="show ? 'show' : ''"
        :style="{ display: show ? 'block' : 'none' }"
    >
        <div
            class="modal-dialog modal-dialog-centered"
            role="document"
        >
            <div class="modal-content">
                <div class="modal-header d-flex flex-column">
                    <button
                        v-show="!disableClose"
                        type="button"
                        class="btn-close"
                        data-dismiss="modal"
                        aria-label="Close"
                        @click="$emit('close')"
                    />
                    <h5 class="title">
                        {{ title }}
                    </h5>
                </div>
                <div class="modal-body">
                    <slot />
                </div>
            </div>
        </div>
    </div>
    <!-- NOTE: modal-backdrop is in App.vue to maintain scrollability on desktop -->
</template>

<script>
    export default {
        name: 'Modal',
        props: {
            title: { type: String },
            show: { type: Boolean, default: false },
            disableClose: { type: Boolean, default: false },
        },
        data() {
            return {
                backdropId: `modalbackdrop${this._uid}`,
            }
        },
        destroyed: function () {
            // if a user times out, we want to remove these classes on destroyed
            this.hideBackdrop()
        },
        methods: {
            showBackdrop() {
                const backdrop = document.createElement('div')
                backdrop.id = this.backdropId
                backdrop.classList.add('modal-backdrop')
                backdrop.classList.add('fade')
                backdrop.classList.add('show')
                document.body?.appendChild(backdrop)
            },
            hideBackdrop() {
                document.getElementById(this.backdropId)?.remove()
            },
        },
        watch: {
            show: function (value) {
                if (value) {
                    this.showBackdrop()
                } else {
                    this.hideBackdrop()
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '../styles/components/modal';
</style>
