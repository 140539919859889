<template>
    <modal
        title="How did applicant hear about Aven?"
        :show="show"
        @close="onClose"
    >
        <form-container
            id="referralSourceModal"
            ref="referralSourceModal"
            @on-submit="onSubmitReferralSource"
        >
            <div class="form-row">
                <div class="mb-2 col">
                    <form-radio-group
                        class="radio-left-aligned"
                        :options="options"
                        name="source"
                        v-model="source"
                        validation-rules="required"
                    />
                </div>
            </div>

            <div
                class="form-row"
                v-if="source === ReferralFrom.other"
            >
                <div class="mb-2 col">
                    <form-text-area
                        v-model="otherDescription"
                        name="otherDescription"
                        class="form-field"
                        validation="required|min:5"
                        placeholder="What was the other source?"
                    />
                </div>
            </div>
            <div class="d-grid">
                <form-button
                    class="mb-2"
                    label="Submit referral source"
                    type="submit"
                />
            </div>
        </form-container>
    </modal>
</template>

<script>
    import Modal from '@/components/Modal'
    import FormTextArea from '@/components/base/FormTextArea'
    import FormContainer from '@/components/base/FormContainer'
    import FormButton from '@/components/base/FormButton'
    import { ReferralFrom, referralFromText } from '@/services/remoteNotarizationApi'
    import FormRadioGroup from '@/components/base/FormRadioGroup'
    import { logger } from '@/utils/logger'

    export default {
        name: 'SetReferralSourceModal',
        props: {
            show: { type: Boolean, default: false },
        },
        components: {
            modal: Modal,
            'form-text-area': FormTextArea,
            'form-container': FormContainer,
            'form-button': FormButton,
            'form-radio-group': FormRadioGroup,
        },
        data() {
            return {
                ReferralFrom,
                source: null,
                otherDescription: null,
            }
        },
        computed: {
            options() {
                return Object.values(ReferralFrom).map((referralFrom) => {
                    return {
                        name: referralFromText[referralFrom],
                        value: referralFrom,
                    }
                })
            },
        },
        methods: {
            onClose: function () {
                this.source = null
                this.otherDescription = null
                logger.info('Closing set referral source modal')
                this.$emit('close')
            },
            onSubmitReferralSource: async function () {
                const isValid = await this.$refs.referralSourceModal.$refs.observer.validate()
                if (!isValid) {
                    return
                }
                // If the notary selects 'other' but then switches to another source, we do not want to send
                // the other description with the request.
                const otherDescription = this.source === ReferralFrom.other ? this.otherDescription : null
                const args = { source: this.source, otherDescription }
                this.source = null
                this.otherDescription = null
                logger.info(`onSubmitReferralSource with ${JSON.stringify(args)}`)
                this.$emit('submit', args)
            },
        },
    }
</script>
